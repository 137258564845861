import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import config from 'config';

import { isAcadeumAdministrator, getCourseSectionCost } from '@acadeum/helpers';

import CourseSearch from './CourseSearch_';
import RequestCourseBanner from '../RequestCourseBanner';

import useExportData from './useExportData';

import useLocation from '../../hooks/useLocation';

export default function InstantCourseSearch({
  view = 'sections',
  scope = 'all',
  courseSharingGroupIds,
  showRequestCourseBanner = true,
  ...rest
}) {
  const { isNarrowContent } = useSelector(state => state.app);
  const { user } = useSelector(state => state.auth);

  const {
    courseEnrollmentPricing,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships
  } = useSelector(state => state.coursePricing);

  const location = useLocation();

  // A custom course pricing function that gets applied to the search results.
  const getCourseSectionCost_ = useCallback(({
    section,
    session,
    course,
    courseCustomCosts,
    courseSectionCustomCosts
  }) => {
    return getCourseSectionCost({
      // All properties of `session` are being migrated to `section` in Algolia objects.
      // https://acadeum.atlassian.net/browse/CS-104
      // Until the migration is finished, use `session` to read the `cost`.
      // When the migration is finished, use `section` to read the `cost`.
      cost: session ? session.cost : section.cost,
      courseCustomCosts,
      courseSectionCustomCosts,
      course: {
        credits: course.hours,
        level: course.level,
        teachingInstitutionId: course.institution.id
      },
      courseEnrollmentPricing,
      homeInstitutionId: user.institution.id,
      homeInstitutionTeachingInstitutionRelationshipIds,
      homeConsortiumTeachingInstitutionRelationships
    });
  }, [
    user,
    courseEnrollmentPricing,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships
  ]);

  const viewerCourseSharingGroupIds = useMemo(() => {
    if (!user) {
      return [0];
    }
    return [0].concat(user.institution.courseSharingGroups.map(_ => _.id));
  }, [
    user
  ]);

  const viewerCourseSharingGroupNames = useMemo(() => {
    if (!user) {
      return ['Default'];
    }
    return ['Default'].concat(user.institution.courseSharingGroups.map(_ => _.name));
  }, [
    user
  ]);

  const onExportData = useExportData({
    mode: view
    // lambdaName: 'courseSearch-export',
    // exportedDataTitle: view === 'courses' ? 'Acadeum Courses' : 'Acadeum Course Sections'
  });

  return (
    <CourseSearch
      {...rest}
      view={view}
      scope={scope}
      layout="rowGrid"
      newCourseSearchDesign={Boolean(user)}
      isNarrowContent={isNarrowContent}
      NoResultsComponent={showRequestCourseBanner ? RequestCourseBanner : undefined}
      showPrices={Boolean(user)}
      onExportData={user ? onExportData : undefined}
      getCourseSectionCost={getCourseSectionCost_}
      userInstitutionId={user && user.institution.id}
      canDebugCourseSharingGroups={user && isAcadeumAdministrator(user) && !courseSharingGroupIds}
      userInstitutionIsTeaching={user && user.institution.teaching}
      noSectionsAvailableLabel={view === 'sections' ? undefined : 'Request New Section'}
      noSectionsAvailableLink={view === 'sections' ? undefined : '/courses/{id}/request-section'}
      location={location}
      courseSharingGroupIds={courseSharingGroupIds}
      viewerCourseSharingGroupIds={viewerCourseSharingGroupIds}
      viewerCourseSharingGroupNames={viewerCourseSharingGroupNames}
      consortial={config.courseSearch && config.courseSearch.consortial}
      indexPrefix={config.algolia.prefix}
      appId={config.algolia.appID}
      apiKey={config.algolia.apiKey}
    />
  );
}

InstantCourseSearch.propTypes = {
  view: PropTypes.oneOf(['sections', 'courses']),
  courseSharingGroupIds: PropTypes.arrayOf(PropTypes.number),
  showRequestCourseBanner: PropTypes.bool
};

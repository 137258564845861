import type React from 'react';
import type { FeatureFlags } from '../../types';
import { getFeatureFlag } from '../../setGetFeatures';

interface ToggleFeaturesProps {
  feature: keyof FeatureFlags;
  on: React.ReactElement;
  off: React.ReactElement;
}

export const ToggleFeatures: React.FC<ToggleFeaturesProps> = ({
  feature,
  on,
  off
}) => {
  if (getFeatureFlag(feature)) {
    return on;
  }

  return off;
};

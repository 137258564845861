import type { FC, ReactNode } from 'react';
import React from 'react';

import type { UnstyledTooltipProps } from '../UnstyledTooltip';
import { UnstyledTooltip } from '../UnstyledTooltip';

import styles from './Tooltip.module.scss';
import { BaseButton } from '../BaseButton';

export interface TooltipProps extends Pick<UnstyledTooltipProps, 'placement' | 'className'> {
  id?: string;
  ariaTitle?: string;
  children?: ReactNode;
  content?: ReactNode;
}

export const Tooltip: FC<TooltipProps> = ({
  id,
  className,
  children,
  content,
  ariaTitle,
  placement
}) => {
  return (
    <UnstyledTooltip
      arrow
      id={id}
      className={className}
      tooltipClassName={styles.tooltip}
      arrowClassName={styles.arrow}
      tooltipContent={content}
      triggerElement={BaseButton}
      ariaTitle={ariaTitle}
      placement={placement}
    >
      {children}
    </UnstyledTooltip>
  );
};

Tooltip.displayName = 'Tooltip';

import React, {useCallback, useRef, useState} from 'react';
import type { MutableRefObject } from 'react';

import { DownloadButton, FileUpload, Text } from '@acadeum/ui';
import type { FileUploadRef } from '@acadeum/ui';
import { isJobError } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import { parseFile } from '@acadeum/shared-admin-ui';
import { isAcadeumAdministrator } from '@acadeum/helpers';

import { useBulkCreateOrUpdateStudents } from '../../../../api/jobs';
import STUDENT_SCHEMA from '../../../../helpers/studentImportSchema';

import { getIgnoredColumns, parseHeaders } from '../../uploadDataFile';
import ReviewStudentsData from '../ReviewStudentsData';

import styles from './UploadStudentsData.module.scss';

const STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION: Omit<typeof STUDENT_SCHEMA, 'INSTITUTION ID'> = { ...STUDENT_SCHEMA };
if ('INSTITUTION ID' in STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION) {
  delete STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION['INSTITUTION ID'];
}

const UploadStudentsData = ({
  templateLink,
  institutionId,
  user,
  resetState,
  cache,
  updateCache
}) => {
  const [fileUploadError, setFileUploadError] = useState<string>();

  const bulkCreateOrUpdateStudents = useBulkCreateOrUpdateStudents();
  const fileUploadRef = useRef() as MutableRefObject<FileUploadRef>;

  const fileName = cache?.fileName || '';

  const t = useTranslate('UploadStudentsData');
  const tStudentsDataUpload = useTranslate('StudentsDataUploadPage');

  const getColumnSchema = (column) => STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION[column];

  const getColumnNameByProp = useCallback((column) => {
    const columns = Object.keys(STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION);

    return columns.find((column_) => {
      return STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION[column_].prop === column ? column_ : undefined;
    });
  }, []);

  const parseJobErrors = ({ data }) => {
    const { errors } = data;

    return errors.map(error => {
      const fieldRow = error.field.split('.')[0];
      const row = Number(fieldRow.slice(fieldRow.indexOf('[') + 1, fieldRow.indexOf(']'))) + 2;
      const column = error.field.split('.')[1];

      return {
        error: error.code || error.type,
        value: error.value,
        column: getColumnNameByProp(column),
        row
      };
    });
  };

  const onFileChosen = async (file) => {
    resetState();

    const parseFileReturn = await parseFile(file, { schema: STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION });

    if (parseFileReturn.parsedData.rows.length === 0) {
      return setFileUploadError(t('noData'));
    }

    const { tableData, parsedData, headerRow } = parseFileReturn;
    const { expectedResult, jobValidatedErrors, verified } = await validate(parsedData);
    const parsedErrors = jobValidatedErrors ? parsedData.errors.concat(jobValidatedErrors) : parsedData.errors;
    const hasError = parsedErrors.length > 0;
    const _headerRow = headerRow.map((header, i) => header || `<#${i + 1}>`);
    const ignoredColumns = getIgnoredColumns(_headerRow, STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION);

    const columns = parseHeaders({
      headerRow: _headerRow,
      errors: parsedErrors,
      expectedResult,
      getColumnSchema,
      schema: STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION,
      t: tStudentsDataUpload
    });

    updateCache({
      fileName: file.name,
      hasError,
      ignoredColumns,
      columns,
      tableData,
      parsedData,
      expectedResult,
      parseFileReturn,
      verified
    });
  };

  const validate = async (data) => {
    let expectedResult;
    let jobValidatedErrors;
    let verified = false;

    if (data.errors.length === 0) {
      try {
        expectedResult = await bulkCreateOrUpdateStudents({
          institutionId: isAcadeumAdministrator(user) ? institutionId : undefined,
          rows: data.rows,
          dryRun: true
        });
        verified = true;
      } catch (error) {
        if (isJobError(error)) {
          console.log(error.data.errors);
          jobValidatedErrors = parseJobErrors(error);
        } else {
          console.error(error);
          throw Error(t('validateError'));
        }
      }
    }

    return {
      expectedResult,
      jobValidatedErrors,
      verified
    };
  };

  return (
    <div className={styles.Container}>
      <Text
        as="h2"
        variant="headingSm"
        mb="sm"
      >
        {t('subtitle')}
      </Text>
      <Text className={styles.Description} mb="md" variant="bodyLg">
        {t('description')}
      </Text>
      <DownloadButton mb="lg" url={templateLink}>
        {t('downloadTemplate')}
      </DownloadButton>

      <FileUpload
        ref={fileUploadRef}
        className={styles.UploadStudentsData__dropZone}
        children={t('fileUploadText')}
        fileName={fileName}
        accept={[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv'
        ]}
        uploadButtonTitle={t('uploadButtonTitle')}
        removeButtonTitle={t('removeButtonTitle')}
        onChange={onFileChosen}
        onReset={() => {
          setFileUploadError(undefined);
          resetState();
        }}
        alertProps={fileUploadError ? {
          variant: 'error',
          children: fileUploadError
        } : undefined}
      />

      {cache && cache.parsedData && (
        <ReviewStudentsData
          user={user}
          institutionId={institutionId}
          cache={cache}
          updateCache={updateCache}
          schema={STUDENT_UPLOAD_SCHEMA_WITHOUT_INSTITUTION}
          getColumnSchema={getColumnSchema}
          validate={validate}
        />
      )}
    </div>
  );
};

export default UploadStudentsData;

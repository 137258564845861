import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { redirect, useRoute } from 'react-pages';
import memoize from 'memoize-one';

import { getErrorData } from '@acadeum/helpers';

import LoadingScreen from '../LoadingScreen';
import ErrorSection from '../Error';
import DefaultLayout from '../DefaultLayout';
import VisitorLayout from '../VisitorLayout';

import useLocation from '../../hooks/useLocation';

import getLocationUrl from 'common-lib/lib/getLocationUrl';
import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';

import actions from '../../actions';

const { setAppLoading } = actions;

const getPageDataLoaderForComponent = memoize(_getPageDataLoaderForComponent);

export default function Auth(Component, {
  margin,
  marginTop,
  UnauthenticatedComponent
} = {}) {
  function RequiresLogin({
    pageLoadResult: initialPageLoadResult,
    // `Meta` component property is passed by `react-pages` library when
    // `metaComponentProperty = true` flag is set on the page's `Component`.
    Meta,
    ...props
  }) {
    const user = useSelector(state => state.auth.user);
    const isAuthenticationLoading = useSelector(state => state.auth.isAuthenticationLoading);
    const userDataLoaded = useSelector(state => state.app.userDataLoaded);

    const isAuthenticated = Boolean(user);
    const isNotAuthenticated = !user;

    const [_pageLoadResult, setPageLoadResult] = useState(initialPageLoadResult);

    // `userDataLoaded === false` means there was an error while loading user data.
    const isAuthenticatedButLoadingUserDataHasErrored = isAuthenticated && userDataLoaded === false;
    const isAuthenticatedAndLoadingUserDataIsInProgress = isAuthenticated && userDataLoaded === undefined;
    const isAuthenticatedAndUserDataIsLoaded = isAuthenticated && userDataLoaded;

    // Reset `pageLoadResult` variable value when the user logs out
    // until they log in again.
    const pageLoadResult = isAuthenticated ? _pageLoadResult : undefined;

    const hasLoadedThePage = Boolean(pageLoadResult);
    const canShowThePage = isAuthenticatedAndUserDataIsLoaded && hasLoadedThePage;
    const shouldLoadThePageBeforeShowingIt = isAuthenticatedAndUserDataIsLoaded && !hasLoadedThePage;

    const renderElement = () => {
      if (isAuthenticationLoading) {
        return (
          <LoadingScreen/>
        );
      }

      // The `userDataLoaded` flag postpones the rendering of the page
      // until the user has been "initialized".
      //
      // For example, "initializing" a user involves setting the initial
      // value for the course pricing model of this institution.
      //
      // If the page being loaded was a course page, or a course section page,
      // then it would call `await getCoursePrices()` in its `load()` function
      // to refresh the course pricing model.
      // (or somewhere in its `useEffect()` post-load fetching function)
      //
      // In order for that `await getCoursePrices()` call to not send a request
      // to the server and use the initial value set during user "initialization",
      // such user "initialization" has to finish before the page starts loading.
      //
      // That's what `userDataLoaded` flag is for.

      if (isNotAuthenticated) {
        return (
          <VisitorLayout background margin="vertical" marginTop="large" marginBottom={false}>
            {UnauthenticatedComponent
              ? <UnauthenticatedComponent/>
              : <ErrorSection type="authentication_error"/>
            }
          </VisitorLayout>
        );
      }

      if (isAuthenticatedAndLoadingUserDataIsInProgress) {
        return (
          <LoadingScreen/>
        );
      }

      if (isAuthenticatedButLoadingUserDataHasErrored) {
        return (
          <VisitorLayout background margin="vertical" marginTop="large" marginBottom={false}>
            <ErrorSection/>
          </VisitorLayout>
        );
      }

      // At this point, the user is authenticated and the user data has been loaded.

      if (canShowThePage) {
        return (
          <DefaultLayout margin={margin} marginTop={marginTop} getBreadcrumbs={Component.breadcrumbs}>
            <Component {...props} {...pageLoadResult.props}/>
          </DefaultLayout>
        );
      }

      if (shouldLoadThePageBeforeShowingIt) {
        return (
          <DeferredPageDataLoader
            load={getPageDataLoaderForComponent(Component)}
            loadStateParameters={Component.loadStateParameters}
            onSuccess={(result) => {
              setPageLoadResult({ props: result?.props });
            }}
            onError={(errorData) => {
              redirect(getLocationUrl({
                pathname: '/error',
                query: {
                  ...errorData,
                  url: `${window.location.pathname}${window.location.search}${window.location.hash}`
                }
              }));
            }}
          />
        );
      }

      return null;
    };

    if (Meta) {
      return (
        <>
          <Meta withPageLoadResultLatest pageLoadResultLatest={pageLoadResult}/>
          {renderElement()}
        </>
      );
    }

    // Not passing `Meta` component property is fine in cases when `Component.meta()`
    // is not defined because there's no meta to apply on a page anyway.
    if (Component.meta) {
      console.error('`Component.meta()` function is defined but `Meta` component property wasn\'t passed to `<RequiresLogin/>` component that was returned from `Auth(Component)` function. To pass `Meta` property component, set `metaComponentProperty = true` flag on a corresponding route\'s `Component`');
    }
    return renderElement();
  }

  RequiresLogin.load = async ({ location, params, useSelector }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useSelector(state => state.auth.user);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userDataLoaded = useSelector(state => state.app.userDataLoaded);

    const isAuthenticated = Boolean(user);
    const isAuthenticatedAndUserDataIsLoaded = isAuthenticated && userDataLoaded;

    // If the user is already authenticated and loaded by the time navigation happens
    // then just load the page's data normally.
    // Otherwise, wait for the user to be authenticated and for the user's data to be loaded.
    if (isAuthenticatedAndUserDataIsLoaded) {
      // Load the page.
      const load = getPageDataLoaderForComponent(Component);
      const result = await load({
        location,
        params,
        // dispatch,
        useSelector,
        user
      });
      if (result && result.redirect) {
        // The page hasn't been loaded.
        return result;
      } else {
        return {
          ...result,
          props: {
            ...(result && result.props),
            pageLoadResult: {
              props: result && result.props
            }
          }
        };
      }
    }
  };

  // Just reassigning meta wouldn't work in the cases
  // when the `meta()` function uses `state` and expects it
  // to be already loaded with the data that is loaded in `load()` function.
  // RequiresLogin.meta = Component.meta;
  //
  // Instead, `updateMeta()` function from `react-pages` is called
  // in case of a client-side-only load.
  //
  RequiresLogin.meta = ({
    props: {
      withPageLoadResultLatest,
      pageLoadResultLatest,
      pageLoadResult: pageLoadResultInitial
    },
    useSelector
  }) => {
    // `useSelector()` parameter has to be passed to `Component.meta()` function.
    // But React hooks aren't allowed to be called inside `if`s.
    // A workaround is to get the whole `state` using `useSelector()` hook
    // and then create a "fake" `useSelector()` function that could be used inside `if`s.
    //
    // An inefficiency of such approach would be that the page component will re-render
    // on any change of Redux state. And `.meta()` will be recalculated and re-compared
    // on each such render. But I guess the difference in performance would be negligible.
    //
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const state = useSelector(state => state);
    const useSelectorFunction = (selector) => selector(state);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const user = useSelector(state => state.auth.user);

    // Because `meta()` gets recalculated in real time,
    // it should use the latest `pageLoadResult` value here,
    // not just the initial `props.pageLoadResult`.
    // Otherwise, it would potentially not work correctly when the user logs out
    // because `pageLoadResult` gets reset in that case.
    const pageLoadResult = withPageLoadResultLatest ? pageLoadResultLatest : pageLoadResultInitial;
    const hasLoadedThePage = Boolean(pageLoadResult);

    if (hasLoadedThePage) {
      // Only call the component's `meta` function after the user has been loaded.
      if (Component.meta) {
        return Component.meta({ ...pageLoadResult, useSelector: useSelectorFunction });
      }
    }

    return {};
  };

  RequiresLogin.metaComponentProperty = true;

  RequiresLogin.propTypes = {
    Meta: PropTypes.elementType,
    pageLoadResult: PropTypes.shape({
      props: PropTypes.object
    })
  };

  return RequiresLogin;
}

function DeferredPageDataLoader({
  load,
  loadStateParameters,
  onSuccess,
  onError
}) {
  const dispatch = useDispatch();

  const state = useSelector(state => state);
  const user = useSelector(state => state.auth.user);

  const location = useLocation();
  const route = useRoute();

  const params = route.params;

  // Get `state` parameter for the page data loader function.
  let stateParameter;
  if (loadStateParameters) {
    stateParameter = {};
    for (const key of Object.keys(loadStateParameters)) {
      stateParameter[key] = loadStateParameters[key](state);
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        setAppLoading(true);
        const result = await load({
          location,
          params,
          user,
          // dispatch,
          useSelector: selector => selector(state)
        });
        if (result && result.redirect) {
          // The page hasn't been loaded.
          dispatch(redirect(result.redirect.url));
        } else {
          onSuccess(result);
        }
      } catch (error) {
        console.error(error);
        onError(getErrorData(error));
      } finally {
        setAppLoading(false);
      }
    }

    loadData();
  }, []);

  return null;
}

DeferredPageDataLoader.propTypes = {
  load: PropTypes.func.isRequired,
  loadStateParameters: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

function validateAcadeumAdministrator({ location, user }) {
  if (location.pathname === '/admin' || location.pathname.indexOf('/admin/') === 0) {
    // Restrict admin pages to "Acadeum Administrator" users only.
    if (!isAcadeumAdministrator(user)) {
      const error = new Error('Unauthorized');
      error.data = { type: 'unauthorized' };
      throw error;
    }
  }
}

function validateWhenAccess({ Component, user }) {
  if (typeof Component.when === 'function') {
    if (!Component.when({ user })) {
      const error = new Error('Unauthorized');
      error.data = { type: 'unauthorized' };
      throw error;
    }
  }
}

function _getPageDataLoaderForComponent(Component) {
  return async ({
    location,
    params,
    // dispatch,
    useSelector,
    user
  }) => {
    // Validate Admin Tools access.
    validateAcadeumAdministrator({ location, user });

    validateWhenAccess({ Component, user });

    // Get `state` parameter for the page data loader function.
    let stateParameter;
    if (Component.loadStateParameters) {
      stateParameter = {};
      for (const key of Object.keys(Component.loadStateParameters)) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        stateParameter[key] = useSelector(Component.loadStateParameters[key]);
      }
    }

    // Load page data.
    if (Component.load) {
      return await Component.load({
        location,
        params,
        // dispatch,
        state: stateParameter,
        user
      });
    }
  };
}

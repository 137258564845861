import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchHomeInstitutionTeachingInstitutionRelationships = redux.action(
  () => async http => await http.get('/teaching-institution-relationships'),
  (state, {
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeInstitutionTeachingInstitutionRelationshipIdMax,
    homeConsortiumTeachingInstitutionRelationships
  }) => ({
    ...state,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeInstitutionTeachingInstitutionRelationshipIdMax,
    homeConsortiumTeachingInstitutionRelationships
  })
);

export const setHomeInstitutionTeachingInstitutionRelationships = redux.simpleAction(
  (state, {
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeInstitutionTeachingInstitutionRelationshipIdMax,
    homeConsortiumTeachingInstitutionRelationships
  }) => ({
    ...state,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeInstitutionTeachingInstitutionRelationshipIdMax,
    homeConsortiumTeachingInstitutionRelationships
  })
);

// This will be replaced with the redux action below.
// https://github.com/Acadeum/ASP-API/pull/519
export const fetchCourseEnrollmentPricing = redux.action(
  () => async http => ({
    courseEnrollmentPricing: await http.get('/enrolling-course-pricing')
  })
);
// The replacement:
// export const fetchCourseEnrollmentPricing = redux.action(
//   () => async http => await http.get('/course-enrollment-pricing')
// );

export const setCourseEnrollmentPricing = redux.simpleAction(
  (state, {
    courseEnrollmentPricing,
    // `courseEnrollmentPricingConsortia` property will be added after the pull request is merged: https://github.com/Acadeum/ASP-API/pull/519
    courseEnrollmentPricingConsortia,
    // `homeInstitutionTeachingInstitutionRelationshipIds` and `homeConsortiumTeachingInstitutionRelationships` properties
    // will be added after the pull request is merged: https://github.com/Acadeum/ASP-API/pull/519
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships
  } = {}) => ({
    ...state,
    courseEnrollmentPricing,
    // `courseEnrollmentPricingConsortia` property will be added after the pull request is merged: https://github.com/Acadeum/ASP-API/pull/519
    courseEnrollmentPricingConsortia,
    // `homeInstitutionTeachingInstitutionRelationshipIds` and `homeConsortiumTeachingInstitutionRelationships` properties
    // will be added after the pull request is merged: https://github.com/Acadeum/ASP-API/pull/519
    // Until then, don't overwrite the one that is already in `state`.
    homeInstitutionTeachingInstitutionRelationshipIds: homeInstitutionTeachingInstitutionRelationshipIds || state.homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships: homeConsortiumTeachingInstitutionRelationships || state.homeConsortiumTeachingInstitutionRelationships
  })
);

export default redux.reducer();

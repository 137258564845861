import getMinCourseSectionLastAddDateTimeWhenStillCanRequestEnrollmentInCourse from './getMinCourseSectionLastAddDateTimeWhenStillCanRequestEnrollmentInCourse.js';

export default function canEnrollInCourse({
  onDemand,
  lastAddDate
}) {
  if (onDemand) {
    return true;
  }
  return lastAddDate.getTime() >= getMinCourseSectionLastAddDateTimeWhenStillCanRequestEnrollmentInCourse();
}

import formatCountryRegion from 'common-lib/lib/formatCountryRegion';
import COUNTRY_REGIONS from 'common-lib/constants/countryRegions.json';

import type { Options } from '@acadeum/types';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

type getCountryRegionOptions = (country?: string) => Options<string>;

export const getCountryRegionOptions: getCountryRegionOptions = (country) => {
  if (country && COUNTRY_REGIONS[country]) {
    return COUNTRY_REGIONS[country].map((region) => ({
      label: formatCountryRegion(country, region, { language }),
      value: region
    }));
  }
  return [{
    label: 'N/A',
    value: 'N/A'
  }];
};

import { getAccessTokenFromLocalStorage } from '@acadeum/auth';
import { getErrorData } from '@acadeum/helpers';

import getLocationUrl from 'common-lib/lib/getLocationUrl';
import isRelativeUrl from 'common-lib/lib/isRelativeUrl';

import config from 'config';

import Container from './components/Container/index.js';

import onNotLoggedIn from './helpers/onNotLoggedIn';
import { reduxMiddleware } from './helpers/redux';

import * as reducers from './redux/index.with-hot-reload.js';
import { notify } from './redux/notificationModal.js';

import routes from './routes';

export default {
  // `react-router` routes.
  routes,

  // Redux reducers.
  reducers,

  reduxMiddleware,

  rootComponent: Container,

  // Could also check for weird AWS API Gateway "error" (HTTP status 200) responses.
  // Example:
  // {
  //   errorType: "Function.ResponseSizeTooLarge",
  //   errorMessage: "Response payload size (7284959 bytes) exceeded maximum allowed payload size (6291556 bytes)."
  // }
  http: {
    transformUrl(url) {
      // Transforms all relative URLs to absolute API URLs.
      // Third party websites must not have relative `url`s
      // because such relative `url` queries always have
      // an authentication token attached.
      if (isRelativeUrl(url)) {
        return `${config.apiUrl}${url}`;
      }
      return url;
    },

    // Allows sending cookies to and receiving cookies from
    // "acadeum.com" domain or any of its sub-domains.
    //
    // Requires `OPTIONS` HTTP response to return a correct `` header.
    // Otherwise, it throws:
    //
    // "Access to XMLHttpRequest at 'https://dev-api.acadeum.com/authenticate'
    //  from origin 'http://localhost:3002' has been blocked by CORS policy:
    //  Response to preflight request doesn't pass access control check:
    //  The value of the 'Access-Control-Allow-Origin' header in the response
    //  must not be the wildcard '*' when the request's credentials mode is 'include'".
    //
    useCrossDomainCookies({ belongsToDomain }) {
      return belongsToDomain('acadeum.com');
    },

    authentication: {
      // If a token is returned from this function, it gets sent as
      // `Authorization: Bearer {token}` HTTP header.
      //
      // Even though the Course Share website normally uses cookies for authentication,
      // cookies aren't used when the website is run in development on `localhost`.
      // So `Authorization: Bearer {token}` is still used when running on `localhost`.
      //
      accessToken({ originalUrl }) {
        // It's recommended to check the `url` to make sure that the access token
        // is not leaked to a third party: only send it to your own servers.
        if (isRelativeUrl(originalUrl)) {
          if (typeof localStorage !== 'undefined') {
            return getAccessTokenFromLocalStorage();
          }
        }
      }
    },

    onError(error, { url, redirect, dispatch }) {
      if (error.data) {
        if (
          error.status === 401 || error.data && (
            error.data.code === 'access_token_expired' ||
            error.data.code === 'access_token_invalid' ||
            // `value` contains the inactive user ID when the action was performed
            // not by the user themselves, but rather by an Acadeum admin.
            (
              (error.data.code === 'account_inactive' || error.data.code === 'institution_inactive') &&
              !error.data.value
            )
          )
        ) {
          // Cookies have been cleared on server-side when it detected a status `401` error.
          dispatch({ type: 'NOT AUTHENTICATED' });
          onNotLoggedIn();
          redirect(getLocationUrl({
            pathname: '/error',
            query: {
              ...error.data,
              url
            }
          }));
          return true;
        }
      }
      if (error.status === 503) {
        dispatch(notify('The servers are under maintenance. Check back again soon.', { type: 'warning' }));
        return true;
      }
    },

    // (`react-pages`-only)
    // Converts `Error` instance into a plain JSON object
    // for storing it in Redux state.
    getErrorData
  },

  onLoadError(error, { location, url, redirect, dispatch }) {
    console.error(`Error while preloading "${url}"`);
    console.error(error);
    const redirectToErrorPage = (pathname, parameters) => {
      // Prevents infinite redirection loop or double redirection.
      // For example, an infinite redirection loop in case of `/error`
      // when there're overall page rendering bugs, etc.
      if (location.pathname !== pathname) {
        redirect(getLocationUrl({
          pathname,
          query: {
            ...parameters,
            url
          }
        }));
      }
    };
    // Not found.
    // (Algolia errors have `.statusCode` property name for HTTP response status)
    if (error.status === 404 || error.statusCode === 404) {
      return redirectToErrorPage('/not-found');
    }
    // Not authenticated.
    if (error.status === 401 || error.data && (
      error.data.code === 'access_token_expired' ||
      error.data.code === 'access_token_invalid' ||
      // `value` contains the inactive user ID when the action was performed
      // not by the user themselves, but rather by an Acadeum admin.
      (
        (error.data.code === 'account_inactive' || error.data.code === 'institution_inactive') &&
        !error.data.value
      )
    )) {
      // Cookies have been cleared on server-side when it detected a status `401` error.
      dispatch({ type: 'NOT AUTHENTICATED' });
      onNotLoggedIn();
    }
    return redirectToErrorPage('/error', {
      type: error.data && error.data.type,
      code: error.data && error.data.code
    });
  }
};

import { useCallback, useRef, useState } from 'react';

import type { Option, Options } from '@acadeum/types';
import { useUpdateEffect, useIsMounted } from '@acadeum/hooks';

export const DEBOUNCE_INTERVAL = 200;
export const EMPTY_VALUE = null;

interface UseFilteredOptionsProps<V> {
  async?: boolean;
  isLoading?: boolean;
  options: Options<V | null>;
  transformOptions?: (options: Options<V>) => Options<V>;
  fetchOptions?: (search: string) => Promise<Options<V>>;
  findOptionByValue?: (value: V) => Promise<Option<V>>;
}

export function useFilteredOptions<V = string>(props: UseFilteredOptionsProps<V>) {
  if (props.async) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useAsyncFilteredOptions(props);
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSyncFilteredOptions(props);
}

function useSyncFilteredOptions<V>({
  options: propsOptions,
  transformOptions,
  isLoading,
  ...rest
}: UseFilteredOptionsProps<V>) {
  const [filteredOptions, setFilteredOptions] = useState(propsOptions ? propsOptions : []);

  const updateFilteredOptions = (options) => {
    if (transformOptions) {
      options = transformOptions(options);
    }
    setFilteredOptions(options);
  };

  useUpdateEffect(() => {
    updateFilteredOptions(propsOptions);
  }, [propsOptions]);

  const fetchOptions = (filterValue) => {
    if (!filterValue) {
      updateFilteredOptions(propsOptions);
      return propsOptions;
    }

    const options = propsOptions.filter(option => {
      const extendValue = option.label + ' ' + option.keywords?.filter(Boolean).join(' ');
      return extendValue.toLowerCase().includes(filterValue.toLowerCase());
    });

    updateFilteredOptions(options);
    return options;
  };

  const findOptionByValue = useCallback((value) => {
    return propsOptions.find(option => option.value === value);
  }, [propsOptions]);

  const findOptionsByValues = useCallback((values) => {
    return propsOptions.filter(option => values.some(value => option.value === value));
  }, [propsOptions]);

  return {
    ...rest,
    isLoading,
    options: filteredOptions,
    findOptionByValue,
    findOptionsByValues,
    fetchOptions
  };
}

function useAsyncFilteredOptions<V>({
  options: propsOptions,
  isLoading: propsIsLoading = false,
  findOptionByValue: propsFindOptionByValue,
  fetchOptions: propsFetchOptions,
  transformOptions,
  ...rest
}: UseFilteredOptionsProps<V>) {
  const isMounted = useIsMounted();
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState(propsOptions ? propsOptions : []);

  const isInitializingItemForValue = useRef(false);
  const cancelFetchOptions = useRef<(() => void) | null>(null);
  const cancelGetOptionForValue = useRef<(() => void) | null>(null);

  const updateFilteredOptions = (options) => {
    if (transformOptions) {
      options = transformOptions(options);
    }
    setOptions(options);
  };

  const onCancelFetchOptions = useCallback(() => {
    if (typeof cancelFetchOptions.current === 'function') {
      cancelFetchOptions.current();
      cancelFetchOptions.current = null;
    }
  }, []);

  const onCancelGetOptionForValue = useCallback(() => {
    if (typeof cancelGetOptionForValue.current === 'function') {
      cancelGetOptionForValue.current();
      cancelGetOptionForValue.current = null;
    }
  }, []);

  const fetchOptions = (inputValue) => {
    if (isInitializingItemForValue.current) {
      return;
    }
    onCancelFetchOptions();
    if (inputValue === '') {
      setLoading(false);
      return updateFilteredOptions([]);
    }

    let cancelled;
    setLoading(true);
    cancelFetchOptions.current = () => {
      cancelled = true;
      clearTimeout(debounceTimeout);
      if (isMounted()) {
        setLoading(false);
      }
    };

    // Basic debounce to reduce the amount of queries sent to the server.
    const debounceTimeout = setTimeout(async () => {
      if (cancelled || !isMounted()) {
        return;
      }
      try {
        const options = await propsFetchOptions?.(inputValue);
        if (cancelled || !isMounted()) {
          return;
        }
        onCancelFetchOptions();
        updateFilteredOptions(options);
      } catch (error) {
        if (cancelled || !isMounted()) {
          return;
        }
        onCancelFetchOptions();
        console.error(error);
      }
    }, DEBOUNCE_INTERVAL);
  };

  const findOptionByValue = async (valueOrValues, { multiple = false } = {}) => {
    if (multiple ? valueOrValues.length === 0 : valueOrValues === EMPTY_VALUE) {
      return updateFilteredOptions([]);
    }

    onCancelFetchOptions();
    onCancelGetOptionForValue();
    let cancelled;
    setLoading(true);
    cancelGetOptionForValue.current = () => {
      cancelled = true;
      if (isMounted()) {
        setLoading(false);
      }
    };

    try {
      const result = await (multiple ? Promise.all(valueOrValues.map(propsFindOptionByValue)) : propsFindOptionByValue?.(valueOrValues));
      if (cancelled) {
        return;
      }
      onCancelGetOptionForValue();
      if (multiple ? Array.isArray(result) && result.length > 0 : result) {
        isInitializingItemForValue.current = true;
        updateFilteredOptions(multiple ? result : [result]);
        return result;
      }
    } catch (error) {
      onCancelGetOptionForValue();
      console.error(error);
    } finally {
      isInitializingItemForValue.current = false;
    }
  };

  const findOptionsByValues = async (values) => {
    return await findOptionByValue(values, { multiple: true });
  };

  return {
    ...rest,
    options,
    isLoading: propsIsLoading || isLoading,
    fetchOptions,
    findOptionByValue,
    findOptionsByValues
  };
}

import formatTerm from 'common-lib/lib/formatTerm.js';
import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

const TERMS = [
  'Winter',
  'Spring',
  'Summer',
  'Fall'
];

function getTermIndexByMonthIndex(monthIndex: number): number {
  if (monthIndex < 0 || monthIndex > 11) {
    throw new Error('Invalid month index');
  }

  const WINTER = 0;
  const SPRING = 1;
  const SUMMER = 2;
  const FALL = 3;

  const termByMonth = [
    WINTER,  // January
    WINTER,  // February
    SPRING,  // March
    SPRING,  // April
    SPRING,  // May
    SUMMER,  // June
    SUMMER,  // July
    SUMMER,  // August
    FALL,    // September
    FALL,    // October
    FALL,    // November
    WINTER   // December
  ];

  return termByMonth[monthIndex];
}

export function getTermsStartingFromPresent(count: number, date = new Date()): string[] {
  const monthIndex = date.getMonth();

  let term = getTermIndexByMonthIndex(monthIndex);
  let year = date.getFullYear();

  // If the current month is December, reset to Winter and increment the year
  if (monthIndex === 11) {
    term = 0;
    year++;
  }

  const results: [string, number][] = [];

  for (let i = 0; i < count; i++) {
    results.push([TERMS[term], year]);
    term = (term + 1) % TERMS.length;

    // Increment the year when the term wraps back to Winter
    if (term === 0) {
      year++;
    }
  }

  // If started in December, adjust the year for the first term
  if (monthIndex === 11) {
    results[0][1]--;
  }

  return results.map(([term, year]) => `${term} ${year}`);
}

export const TERM_OPTIONS = getTermsStartingFromPresent(4).concat('Other').map((term) => ({
  value: term,
  label: formatTerm(term, { language })
}));

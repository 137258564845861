import React from 'react';
import { useSelector } from 'react-redux';

import DefaultPublicMarketingPageBanner from '../DefaultPublicMarketingPageBanner';

export default function WithPublicMarketingPageBanner(Component, { margin } = {}) {
  function DecoratedComponent(propsComponent) {
    const user = useSelector(state => state.auth.user);

    let children = React.createElement(Component, {...propsComponent});

    if (margin === 'horizontal' || margin === true) {
      children = (
        <div
          style={margin === true ? VERTICAL_MARGIN_STYLE : undefined}
          className="container">
          {children}
        </div>
      );
    }

    return (
      <>
        {!user && (
          <DefaultPublicMarketingPageBanner/>
        )}
        {children}
      </>
    );
  }

  DecoratedComponent.load = Component.load;
  DecoratedComponent.meta = Component.meta;
  DecoratedComponent.breadcrumbs = Component.breadcrumbs;

  DecoratedComponent.propTypes = Component.propTypes;

  return DecoratedComponent;
}

const VERTICAL_MARGIN_STYLE = {
  marginTop: '1.5rem',
  marginBottom: '3rem'
};

import React from 'react';

import type { RowData } from '@tanstack/react-table';

import formatDate from 'common-lib/lib/formatDate';

import { DateRangePicker } from '../../../DateRangePicker';

import type { FilterItemObject } from '../types';

export const createTableDateRangeFilter = <TData extends RowData>({
  id
}: {
  id: string;
}): FilterItemObject<TData> => {
  return {
    id,
    filter(row, value) {
      const [ fromDate, toDate ] = value;
      if (fromDate && !toDate) {
        return row[id] === fromDate;
      }
      return row[id] >= fromDate && row[id] <= toDate;
    },
    render({ getValue, setFilterValues }) {
      return (
        <DateRangePicker
          isFilter
          utc
          label="Date range"
          value={getValue() ?? null}
          onChange={(range) => {
            const [ fromDate, toDate ] = range;
            let label = '';
            if (fromDate && toDate) {
              label = `${formatDate(fromDate, { month: 'short', utc: false })} - ${formatDate(toDate, { month: 'short', utc: false })}`;
            } else if (fromDate && !toDate) {
              label = formatDate(fromDate, { month: 'short', utc: false });
            }

            setFilterValues(prev => ({
              ...prev,
              [id]: {value: range, label}
            }));
          }}
        />
      );
    }
  };
};

import React, { useCallback, useEffect, useMemo } from 'react';

export interface FilterDefinition<Value = any> {
  name: string;
  label: string;
  getFilterLabel: (value: Value, item: this) => string;
  shouldShow?: (item: this) => boolean;
}

export interface FilterOption<Value = any> {
  name: string;
  // When the filter value is null, it means the filter should be removed
  value: Value | null;
  label?: string;
  getFilterLabel: (value: Value, item: FilterOption<Value>) => string;
  shouldShow?: (item: FilterOption<Value>) => boolean;

  [key: string]: any;
}

export interface BaseFilterProps<Value = any, D = FilterDefinition<Value>> {
  name: string;
  label: string;
  getFilterLabel?: (value: Value, filter: D) => string;
}

interface FiltersContextProps<Value = any, D extends FilterDefinition<Value> = FilterDefinition<Value>> {
  registeredFilters: Record<string, D>;
  registerFilter: (filter: D) => void;
  unregisterFilter: (filter: D) => void;
  onFilterChange: (value: Value | null, filter: D) => void;
  clearAll: () => void;
  removeFilter: (filter: D, value: Value | null) => void;
  visibleFilters: Record<string, { filter: FilterDefinition, value: Value }>;
}

const FiltersContext = React.createContext<FiltersContextProps | undefined>(undefined);

export function useFiltersContext() {
  const context = React.useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a <Filters> component');
  }
  return context;
}

interface FiltersProviderProps {
  children?: React.ReactNode;
  values: object;
  onFiltersChange: (values: object) => void;
}

export const FiltersProvider: React.FC<FiltersProviderProps> = ({
  onFiltersChange,
  values,
  children
}) => {
  const [registeredFilters, setRegisteredFilters] = React.useState<Record<string, FilterDefinition>>({});

  const registerFilter = useCallback((filter: FilterDefinition) => {
    setRegisteredFilters((prev) => ({ ...prev, [filter.name]: filter }));
  }, [setRegisteredFilters, values]);

  const unregisterFilter = useCallback((filter: FilterDefinition) => {
    setRegisteredFilters((prev) => {
      const newRegisteredFilters = { ...prev };
      delete newRegisteredFilters[filter.name];
      return newRegisteredFilters;
    });
  }, [setRegisteredFilters]);

  const onFilterChange = (value, filter: FilterDefinition) => {
    const newValues = { ...values };
    if (value === null) {
      delete newValues[filter.name];
    } else {
      newValues[filter.name] = value;
    }
    onFiltersChange(newValues);
    setRegisteredFilters((prev) =>  ({ ...prev, [filter.name]: filter }));
  };

  const clearAll = () => {
    onFiltersChange({});
  };

  const removeFilter = (filter: FilterDefinition, value) => {
    onFilterChange(value, filter);
  };

  const visibleFilters = useMemo(() => {
    const result: Record<string, { filter: FilterDefinition, value: any }> = {};

    for (const [key, value] of Object.entries(values)) {
      const filter = registeredFilters[key];
      if (filter) {
        const shouldShow = filter.shouldShow ? filter.shouldShow(filter) : true;
        if (shouldShow) {
          result[key] = { filter, value };
        }
      }
    }

    return result;
  }, [registeredFilters, values]);

  return (
    <FiltersContext.Provider value={{
      registerFilter,
      unregisterFilter,
      onFilterChange,
      registeredFilters,
      visibleFilters,
      removeFilter,
      clearAll
    }}>
      {children}
    </FiltersContext.Provider>
  );
};

export interface UseRegisterFilterOptions<V = any, D extends FilterDefinition<V> = FilterDefinition<V>> {
  filter: D;
  propsOnChange?: (value: V | null) => V | null;
}

interface UseRegisterFilterReturn<V = any, D extends FilterDefinition<V> = FilterDefinition<V>> {
  value: V | null;
  option: D | undefined;
  onChange: (value: V | null, filterDefinition: D) => void;
}

export function useRegisterFilter<V = any, D extends FilterDefinition<V> = FilterDefinition<V>>({
  filter,
  propsOnChange
}: UseRegisterFilterOptions<V, D>): UseRegisterFilterReturn<V, D> {
  const { registerFilter, unregisterFilter, visibleFilters, onFilterChange } = useFiltersContext();

  useEffect(() => {
    const filter_ = { ...filter };

    if (!filter_.getFilterLabel) {
      filter_.getFilterLabel = (value) => String(value);
    }
    registerFilter(filter_);
    return () => {
      unregisterFilter(filter_);
    };
  }, [
    registerFilter,
    unregisterFilter,
    filter
  ]);

  const onChange = (v: V | null, filterDefinition: D) => {
    onFilterChange(propsOnChange ? propsOnChange(v) : v, filterDefinition);
  };

  return {
    option: visibleFilters[filter.name]?.filter as D | undefined,
    value: visibleFilters[filter.name]?.value,
    onChange
  };
}

import {
  getUseCoursesBulkCreateOrUpdateJob,
  getUseJob,
  getUseCourseSettingsUpdateJob,
  getUseBulkCreateOrUpdateStudentsJob
} from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const useJob = getUseJob(rtkApi);

export const useBulkCreateOrUpdate = getUseCoursesBulkCreateOrUpdateJob(useJob);
export const useUpdateCourseSettings = getUseCourseSettingsUpdateJob(useJob);
export const useBulkCreateOrUpdateStudents = getUseBulkCreateOrUpdateStudentsJob(useJob);

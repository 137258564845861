import React from 'react';

import { COURSE_LEVEL_OPTIONS, TERM_OPTIONS } from '@acadeum/selection-options';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormSubmit from '../../components/FormSubmit';
import FormButtons from '../../components/FormButtons';
import Row from '../../components/Row';
import Section from '../../components/Section';

import actions from '../../actions';

const {
  requestCourse,
  notify,
  goto
} = actions;

export default function RequestCourse() {
  async function onSubmit(values) {
    await requestCourse(values);
    notify('Course requested! We will reach out soon.');
    goto('/');
  }

  return (
    <Section
      title="Can’t seem to find a course you’re looking for?"
      description="You can request this course be added.">

      <Form
        autoFocus
        className="form"
        onSubmit={onSubmit}>

        <Row>
          <Field
            required
            name="course"
            label="Course Name"
            col={6} />

          <Field
            required
            name="category"
            label="Course Category"
            col={6} />
        </Row>

        <Row>
          <Field
            required
            name="level"
            label="Level"
            col={6}
            placeholder="Select a Level"
            options={COURSE_LEVEL_OPTIONS} />

          <Field
            required
            name="term"
            label="Term"
            col={6}
            placeholder="Select a Term"
            options={TERM_OPTIONS} />
        </Row>

        <Row>
          <Field
            required
            name="seatsCount"
            label="Number of Enrollments"
            col={6}
            type="integer" />
        </Row>

        <FormButtons>
          <FormSubmit>
            Request Course
          </FormSubmit>
        </FormButtons>
      </Form>
    </Section>
  );
}

RequestCourse.meta = () => ({
  title: 'Request a Course'
});

RequestCourse.breadcrumbs = () => [
  'Request a Course'
];

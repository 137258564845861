import common from '../../../common.en.json';

import feeType from '../../../options/feeType.en.json';
import stripe from '../../../options/stripe.en.json';

const localization = {
  common,
  feeType,
  stripe
};

const locale = {
  'selection-options': localization
};

export default locale;

import UTC_DATE_TIME_OFFSET from '../constants/utcDateTimeOffset.js';

const ONE_DAY = 24 * 60 * 60 * 1000;

// Limit the courses being shown to a student to max 240 days in the future.
// https://github.com/Acadeum/Tickets/issues/195
export default function getMaxCourseSectionStartDateTimeTillWhichStudentsAreAbleToRequestEnrollment() {
  // `startDate` has `00:00` time in `UTC+0` time zone.
  // Hence, `UTC_DATE_TIME_OFFSET` should be added to it.
  //
  // const dateWhenCourseStarts = startDate + UTC_DATE_TIME_OFFSET;
  // Can enroll in course when: dateWhenCourseStarts + 240 * ONE_DAY < Date.now();
  //
  // =>
  // startDate + UTC_DATE_TIME_OFFSET < Date.now() + 240 * ONE_DAY;
  //
  // =>
  // startDate < Date.now() + 240 * ONE_DAY - UTC_DATE_TIME_OFFSET;
  //
  // =>
  // startDate <= Date.now() + 240 * ONE_DAY - UTC_DATE_TIME_OFFSET - 1;

  return Date.now() + 240 * ONE_DAY - UTC_DATE_TIME_OFFSET - 1;
}

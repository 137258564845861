import React, { memo, useCallback, useMemo, useState } from 'react';

import type { ActionsProps, FormModalProps, OnSubmit } from '@acadeum/ui';
import {
  Actions,
  Alert,
  Checkbox,
  Col,
  ContentSection,
  FormField,
  FormModal,
  List,
  Row,
  SectionCard,
  Text,
  toast
} from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import { CircleExclamationSolidIcon } from '@acadeum/icons';
import type { Course, Section } from '@acadeum/types';
import {
  EMPTY_SCHEDULE_TEXT,
  getEndDateMessageForOnDemandCourseEnrollment,
  getLastDropDateMessageForOnDemandCourseEnrollment
} from '@acadeum/helpers';

import { getEnrollableSections } from '../../../../helpers/course';
import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../helpers/algolia';

import actions from '../../../../actions';

import styles from './CourseSectionsTab.module.scss';

const {
  deactivateSection,
  updateCourseSection,
  createCourseSection
} = actions;

interface CourseSectionsTabProps {
  // eslint-disable-next-line
  course: any;
  refresh: () => Promise<void>;
}

export const CourseSectionsTab = memo<CourseSectionsTabProps>(({
  course,
  refresh
}) => {
  const { onDemand } = course;

  const sections = useMemo(() => {
    return getEnrollableSections(structuredClone(course).sections, course);
  }, [course]);

  const [showRemoveSectionsModal, setShowRemoveSectionsModal] = useState(false);
  const [selectedSections, setSelectedSections] = useState<Section[]>([]);

  const [showCreateSectionModal, setShowCreateSectionModal] = useState(false);
  const [showRemoveSectionModal, setShowRemoveSectionModal] = useState(false);
  const [showUpdateSectionModal, setShowUpdateSectionModal] = useState(false);

  const [sectionForAction, setSectionForAction] = useState<Section | null>(null);

  const closeEditSectionModal = useCallback(() => {
    setShowUpdateSectionModal(false);
    setSectionForAction(null);
  }, [setShowUpdateSectionModal, setSectionForAction]);

  const closeRemoveSectionModal = useCallback(() => {
    setShowRemoveSectionModal(false);
    setSectionForAction(null);
  }, [setShowRemoveSectionModal, setSectionForAction]);

  const closeRemoveSectionsModal = useCallback(() => {
    setSelectedSections([]);
    setShowRemoveSectionsModal(false);
  }, [setSelectedSections, setShowRemoveSectionsModal]);

  const onToggleSection = useCallback((section: Section) => () => {
    setSelectedSections(prevState => prevState.includes(section)
      ? prevState.filter(_ => _ !== section)
      : [...prevState, section]
    );
  }, [setSelectedSections]);

  const onToggleAllSections = useCallback(() => {
    setSelectedSections(prevState => prevState.length === sections.length ? [] : sections);
  }, [setSelectedSections, sections]);

  const actions = useMemo(() => {
    const actions: ActionsProps['actions'] = [
      {
        title: 'Remove Sections',
        onClick: () => setShowRemoveSectionsModal(true),
        disabled: selectedSections.length === 0
      }
    ];

    if(!onDemand) {
      actions.unshift({
        title: 'Add Section',
        onClick: () => setShowCreateSectionModal(true)
      });
    }


    return actions;
  }, [onDemand, selectedSections, setShowCreateSectionModal, setShowRemoveSectionsModal]);

  return (
    <>
      <ContentSection
        title="Course Sections"
        description="Section denotes the term, session, dates, schedule and price for this course. The sections will be listed on the course page."
      >
        <div className={styles.header}>
          <Text
            variant="subtitle2"
            color="graphiteDark"
            style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
          >
            <Checkbox
              onChange={onToggleAllSections}
              checked={sections.length > 0 && selectedSections.length === sections.length}
              indeterminate={selectedSections.length > 0 && selectedSections.length < sections.length}
            />
            {sections.length} Section{sections.length > 1 ? 's' : ''}
          </Text>

          <Actions actions={actions}/>
        </div>

        <br/>

        {sections.map((section) => (
          <SectionCard
            key={section.id}
            checkboxProps={onDemand ? undefined : {
              checked: selectedSections.includes(section),
              onChange: onToggleSection(section)
            }}
          >
            <SectionCard.Header
              section={section}
              actions={[
                {
                  title: 'Edit Section',
                  onClick: () => {
                    setSectionForAction(section);
                    setShowUpdateSectionModal(true);
                  }
                },
                {
                  danger: true,
                  title: 'Remove Section',
                  onClick: () => {
                    setSectionForAction(section);
                    setShowRemoveSectionModal(true);
                  }
                }
              ]}
            />
            {onDemand ? (
              <SectionCard.OnDemandRow
                cost={section.cost}
                onDemandCourseDropDateDaysAfterStartDate={course.institution.onDemandCourseDropDateDaysAfterStartDate}
                onDemandCourseEndDateDaysAfterStartDate={course.institution.onDemandCourseEndDateDaysAfterStartDate}
              />
            ) : (
              <>
                <SectionCard.TermSessionStartEndDateRow section={section}/>
                <SectionCard.Separator/>
                <SectionCard.AddAndDropDateCostRow
                  section={section}
                  cost={section.cost}
                />
                <SectionCard.Separator/>
                <SectionCard.ScheduleRow schedule={section.schedule}/>
              </>
            )}
          </SectionCard>
        ))}
      </ContentSection>

      <SectionFormModal
        mode="create"
        sections={course.sections}
        courseId={course.id}
        show={showCreateSectionModal}
        onHide={setShowCreateSectionModal}
        refresh={refresh}
        onDemand={onDemand}
        onDemandCourseDropDateDaysAfterStartDate={course.institution.onDemandCourseDropDateDaysAfterStartDate}
        onDemandCourseEndDateDaysAfterStartDate={course.institution.onDemandCourseEndDateDaysAfterStartDate}
      />

      {sectionForAction && (
        <SectionFormModal
          mode="update"
          refresh={refresh}
          show={showUpdateSectionModal}
          onHide={closeEditSectionModal}
          section={sectionForAction}
          sections={course.sections}
          onDemand={course.onDemand}
          onDemandCourseDropDateDaysAfterStartDate={course.institution.onDemandCourseDropDateDaysAfterStartDate}
          onDemandCourseEndDateDaysAfterStartDate={course.institution.onDemandCourseEndDateDaysAfterStartDate}
        />
      )}

      {sectionForAction && (
        <RemoveSectionModal
          onDemand={course.onDemand}
          refresh={refresh}
          show={showRemoveSectionModal}
          onHide={closeRemoveSectionModal}
          section={sectionForAction}
        />
      )}

      <RemoveSectionModal
        onDemand={course.onDemand}
        refresh={refresh}
        show={showRemoveSectionsModal}
        onHide={closeRemoveSectionsModal}
        sections={selectedSections}
      />
    </>
  );
});

type SectionFormValues =
  Pick<Section, 'name' | 'schedule' | 'startDate' | 'endDate' | 'lastAddDate' | 'lastDropDate' | 'cost'>
  & {
  fakeSchedule?: string;
  fakeLastDropDate?: string;
  fakeEndDate?: string;
}

interface CreateFormModalProps {
  mode: 'create';
  courseId: Course['id'];
  section?: never;
}

interface EditFormModalProps {
  mode: 'update';
  courseId?: never;
  section: Pick<Section, 'id' | 'name' | 'schedule' | 'cost' | 'lastAddDate' | 'lastDropDate' | 'startDate' | 'endDate' | 'term'> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    session: any
  };
}

type SectionFormModalProps = (CreateFormModalProps | EditFormModalProps) & {
  show: boolean;
  onHide: (value: false) => void;
  refresh: () => Promise<void>;
  sections: Pick<Section, 'id' | 'term' | 'session' | 'startDate' | 'endDate'>[];
  onDemand: Course['onDemand'];
  onDemandCourseDropDateDaysAfterStartDate?: number
  onDemandCourseEndDateDaysAfterStartDate?: number;
}

const SectionFormModal = memo<SectionFormModalProps>(({
  mode,
  onHide,
  show,
  section,
  sections,
  courseId,
  refresh,
  onDemand,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}) => {
  const t = useTranslate('EditCoursePageCourseSectionsTab');

  const isEdit = mode === 'update';

  const onSubmit: OnSubmit<SectionFormValues> = async ({ schedule, ...rest }) => {
    const values = {
      ...rest,
      schedule: schedule && !schedule.toLowerCase().includes('asynchronous') ? schedule : undefined
    };

    delete values.fakeSchedule;
    delete values.fakeLastDropDate;
    delete values.fakeEndDate;

    if (mode === 'update') {
      await updateCourseSection(section.id, values);
    } else {
      await createCourseSection(courseId, values);
    }

    await refresh();
    toast.success(`Section ${isEdit ? 'updated' : 'created'} successfully! ${ALGOLIA_UPDATE_PENDING_NOTE}`);
    onHide(false);
  };

  const defaultValues = useMemo<Partial<SectionFormValues> | undefined>(() => {
    if (mode === 'create') {
      return undefined;
    }
    return {
      ...section,
      schedule: section.schedule || EMPTY_SCHEDULE_TEXT
    };
  }, [section]);

  return (
    <FormModal
      show={show}
      onHide={onHide}
      onCancel={isEdit ? onHide : undefined}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      title={(isEdit ? 'Edit' : 'Add') + ' Section'}
      submitText={isEdit ? 'Update' : 'Add'}
    >
      {({ watch }) => {
        return (
          <FormContent
            t={t}
            mode={mode}
            section={section}
            sections={sections}
            onDemand={onDemand}
            onDemandCourseDropDateDaysAfterStartDate={onDemandCourseDropDateDaysAfterStartDate}
            onDemandCourseEndDateDaysAfterStartDate={onDemandCourseEndDateDaysAfterStartDate}
            watch={watch}
          />
        );
      }}
    </FormModal>
  );
});

function FormContent({
  t,
  mode,
  section,
  sections,
  onDemand,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate,
  watch
}) {
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const otherSectionsInThisSession = useMemo<object[]>(() => {
    let section_: Partial<typeof section> = section;
    if (mode === 'create') {
      if (startDate && endDate) {
        section_ = {
          id: undefined,
          term: undefined,
          session: undefined,
          startDate,
          endDate
        };
      } else {
        return [];
      }
    } else {
      // Initially, the `section` property is `undefined`.
      if (!section_) {
        return [];
      }
    }
    return sections.filter((otherSection) => {
      return (mode === 'create' || otherSection.term === section_?.term) &&
        (mode === 'create' || otherSection.session === section_?.session) &&
        otherSection.startDate.getTime() === section_?.startDate?.getTime() &&
        otherSection.endDate.getTime() === section_?.endDate?.getTime() &&
        otherSection.id !== section_.id;
    });
  }, [
    sections,
    section,
    mode,
    startDate,
    endDate
  ]);

  return (
    <>
      <FormField
        required
        name="name"
        label="Section Name"
        placeholder="e.g. 4SU2024 BUS-274-01D"
      />
      {!onDemand && (
        <FormField
          name="schedule"
          label="Schedule"
          placeholder={'e.g. ' + EMPTY_SCHEDULE_TEXT}
        />
      )}
      <FormField
        required
        label="Cost"
        name="cost"
        type="currency"
        currency="USD"
      />
      <>
        {onDemand && (
          <>
            <FormField
              readOnly
              name="fakeSchedule"
              label="Schedule"
              defaultValue="On-Demand"
            />
            <FormField
              readOnly
              name="fakeLastDropDate"
              label="Drop Date"
              defaultValue={getLastDropDateMessageForOnDemandCourseEnrollment(onDemandCourseDropDateDaysAfterStartDate)}
            />
            <FormField
              readOnly
              name="fakeEndDate"
              label="End Date"
              helpText="Established on the institutional level. To change the Drop & End Dates of On-Demand courses, please work with your Partner Success Manager."
              defaultValue={getEndDateMessageForOnDemandCourseEnrollment(onDemandCourseEndDateDaysAfterStartDate)}
            />
          </>
        )}
      </>
      {otherSectionsInThisSession.length > 0 &&
        <div className={styles.otherSectionsCostOverwriteWarning}>
          <CircleExclamationSolidIcon className={styles.otherSectionsCostOverwriteWarningIcon}/>
          {t('otherSectionsCostOverwriteWarning', {
            count: otherSectionsInThisSession.length,
            mode
          })}
        </div>
      }
      {!onDemand && (
        <>
          <Row>
            <Col md={6}>
              <FormField
                required
                name="startDate"
                label="Start Date"
                type="date"
              />
            </Col>
            <Col md={6}>
              <FormField
                required
                name="endDate"
                label="End Date"
                type="date"
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormField
                required
                name="lastAddDate"
                label="Add Date"
                type="date"
              />
            </Col>
            <Col md={6}>
              <FormField
                required
                name="lastDropDate"
                label="Drop Date"
                type="date"
              />
            </Col>
          </Row>
        </>
      )}
      {/* TODO: Uncomment this when we have the API ready */}
      {/*<FormField*/}
      {/*  name="restrict"*/}
      {/*  type="switch"*/}
      {/*  label="I want to limit this section for students of the following Institution or Consortium"*/}
      {/*  onChange={(event) => {*/}
      {/*    const { checked } = event.target;*/}
      {/*    setRestrict(checked);*/}
      {/*  }}*/}
      {/*/>*/}
      {/*{restrict && (*/}
      {/*  <>*/}
      {/*    <InstitutionAutocomplete*/}
      {/*      required={false}*/}
      {/*      name="institution"*/}
      {/*      type="institution"*/}
      {/*      label="Institution"*/}
      {/*    />*/}
      {/*    <ConsortiumAutocomplete*/}
      {/*      name="consortium"*/}
      {/*      label="Consortium"*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
}

interface RemoveFormValues {
  notes: string;
}

interface RemoveSectionProps {
  section: Pick<Section, 'id' | 'name'>;
  sections?: never;
}

interface RemoveSectionsProps {
  sections: Pick<Section, 'id' | 'name'>[];
  section?: never;
}

type RemoveSectionModalProps =
  (RemoveSectionProps | RemoveSectionsProps)
  & Pick<FormModalProps, 'show' | 'onHide'>
  & {
  refresh: () => Promise<void>
  onDemand: boolean
}

const RemoveSectionModal = ({
  section,
  sections,
  refresh,
  onHide,
  show,
  onDemand
}: RemoveSectionModalProps) => {

  const onSubmit: OnSubmit<RemoveFormValues> = async ({ notes }) => {
    if (section) {
      await deactivateSection(section.id, { notes });
      await refresh();
      onHide(false);
      toast.success('Section removed successfully! ' + ALGOLIA_UPDATE_PENDING_NOTE);
      return;
    }

    const result = await Promise.allSettled(sections.map(section => deactivateSection(section.id, { notes })));
    const rejected = result.filter(item => item.status === 'rejected');
    await refresh();
    onHide(false);

    if (rejected.length === sections.length) {
      toast.error('Failed to remove sections!');
    } else if (rejected.length > 0) {
      toast.warn('Some sections failed to remove! ' + ALGOLIA_UPDATE_PENDING_NOTE);
    } else {
      toast.success('Sections removed successfully! ' + ALGOLIA_UPDATE_PENDING_NOTE);
    }
  };

  return (
    <FormModal
      danger
      submitText="Remove"
      show={show}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onSubmit}
      title={'Remove Section' + (sections ? 's' : '')}
    >
      <Alert variant="warn">
        {section ? (
          onDemand
            ? 'Removing a course section does not affect previously accepted enrollments.'
            : 'Removing a course section <strong>{section.number}</strong> does not affect previously accepted enrollments.'
        ) : (
          <>
            Removing course sections
            {sections.map((section) => (
              <List.Item key={section.id}>
                <strong>Section {section.name}</strong>
              </List.Item>
            ))}
            does not affect previously accepted enrollments.
          </>
        )}
      </Alert>
      <br/>
      <FormField
        required
        noMargin
        multiline
        label="Please, tell us why you are removing these course sections."
        name="notes"
        placeholder="Type here..."
      />
    </FormModal>
  );
};

import type { ErrorData } from './errors';

export class JobError extends Error {
  public data: ErrorData;

  constructor(message: string, data: ErrorData) {
    super(message);
    this.name = 'JobError';
    this.data = data;
  }
}

export const isJobError = (error):  error is JobError => {
  return error instanceof JobError;
};

import React, { useCallback, useRef } from 'react';
import convertToJson from 'read-excel-file/map';

import { Alert, Table, toast } from '@acadeum/ui';
import { StickyFooter } from '@acadeum/shared-admin-ui';
import type { AlertProps } from '@acadeum/ui';
import type { ParseFileReturn } from '@acadeum/shared-admin-ui';
import {isAcadeumAdministrator, isHttpError} from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';

import actions from '../../../../actions';
import { parseHeaders } from '../../uploadDataFile';
import { useUniqErrorsIds } from '../../hooks/useUniqErrorsIds';
import { useBulkCreateOrUpdateStudents } from '../../../../api/jobs';

import styles from './ReviewStudentsData.module.scss';

const {
  goto
} = actions;

const ReviewStudentsData = ({
  user,
  institutionId,
  schema,
  columnPinningRight = ['status'],
  cache,
  updateCache,
  getColumnSchema,
  validate
}) => {
  const uniqErrorsIds = useUniqErrorsIds(cache);
  const rawLoadedDataRef = useRef<ParseFileReturn>(cache?.parseFileReturn);

  const bulkCreateOrUpdateStudents = useBulkCreateOrUpdateStudents();

  const t = useTranslate('ReviewStudentsData');
  const tStudentsDataUpload = useTranslate('StudentsDataUploadPage');

  const alertProps: AlertProps = cache?.hasError ? {
    variant: 'error',
    dismissible: false,
    children: t('errorMessageDetails', { count: uniqErrorsIds?.length })
  } : {};

  const getRowHasError = useCallback(({ row }) => {
    const rowHasError = uniqErrorsIds.find(id => Number(row.id) === id - 2);
    return !!rowHasError;
  }, [uniqErrorsIds]);

  const getCellHasError = useCallback(({ rowIndex, columnId }) => {
    return schema && cache?.parsedData.errors.filter(error => error.column === columnId && error.row === rowIndex + 2)[0];
  }, [cache?.parsedData.errors]);

  const updateData = useCallback((rowIndex, columnId, value) => {
    updateCache(prevState => {
      const { columns, tableData } = prevState;
      const colIndex = columns.findIndex(column => column.id === columnId);

      const headers = prevState.columns.reduce((result, column) => {
        if (column.id) {
          result.push(column.id);
        }
        return result;
      }, []);

      const tableData_ = [...tableData];
      tableData_[rowIndex][colIndex] = value || null;
      const data = [headers, ...tableData_];
      const parseResult = convertToJson(data, schema) as any;

      const columns_ = parseHeaders({
        errors: parseResult.errors,
        getColumnSchema,
        headerRow: rawLoadedDataRef?.current?.headerRow,
        expectedResult: [],
        schema,
        t: tStudentsDataUpload
      });

      return {
        ...prevState,
        columns: columns_,
        tableData: tableData_,
        hasError: parseResult.errors.length > 0,
        parsedData: {
          rows: parseResult.rows,
          errors: parseResult.errors
        },
        verified: false
      };
    });
  }, [cache?.parsedData.rows]);

  const onCancel = () => {
    goto('/students');
  };

  const onUploadData = async () => {
    if (!cache.verified) {
      const { expectedResult, jobValidatedErrors, verified } = await validate(cache.parsedData);
      const parsedErrors = jobValidatedErrors ? cache.parsedData.errors.concat(jobValidatedErrors) : cache.parsedData.errors;

      const columns_ = parseHeaders({
        errors: parsedErrors,
        getColumnSchema,
        headerRow: rawLoadedDataRef?.current?.headerRow,
        expectedResult,
        schema,
        t: tStudentsDataUpload
      });

      updateCache(prevState => {
        return {
          ...prevState,
          columns: columns_,
          expectedResult,
          verified
        };
      });
    } else {
      try {
        if (Array.isArray(cache?.parsedData.rows)) {
          await bulkCreateOrUpdateStudents({
            institutionId: isAcadeumAdministrator(user) ? institutionId : undefined,
            rows: cache?.parsedData.rows,
            dryRun: false
          });

          toast.success(t('successMessage', {
            count: cache?.parsedData.rows.length
          }));
        }
      } catch (error) {
        if (isHttpError(error)) {
          console.log(error.data);
          toast.error(error.data.message);
        }
      }
    }
  };

  return (
    <div>
      {cache && cache.ignoredColumns.length > 0 && (
        <Alert className={styles.DataUploadPage__warning} variant="warn">
          {t('ignoredColumns', { columns: cache.ignoredColumns.join(', ') })}
        </Alert>
      )}

      <Table
        id="studentsDataUploadTable"
        className={styles.ReviewStudentsData__table}
        columns={cache.columns}
        data={cache.tableData}
        alertProps={alertProps}
        columnPinningRight={columnPinningRight}
        meta={{
          getRowHasError,
          getCellHasError,
          updateData
        }}
      />

      <StickyFooter
        cancelProps={{ onClick: onCancel }}
        submitProps={{
          disabled: (cache.hasError || !cache?.fileName),
          children: cache.hasError || !cache.verified ? t('validate') : t('create', { global: true }),
          onClick: onUploadData
        }}
      />
    </div>
  );
};

export default ReviewStudentsData;

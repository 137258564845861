import { ReduxModule } from 'react-pages';

import { getAlgoliaIndex } from '../helpers/algolia';

const redux = new ReduxModule();

// Some pages, like "Change User's Institution" page,
// include an institution select, which is populated from Algolia.
export const fetchInstitutions = redux.action(
  () => async () => {
    return await getAlgoliaIndex('institutions').findAll({ limit: 1000 });
  },
  'institutions'
);

export default redux.reducer();

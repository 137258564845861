import formatYesOrNo from 'common-lib/lib/formatYesOrNo.js';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const YES_OR_NO_OPTIONS = [true, false].map((value) => ({
  value,
  label: formatYesOrNo(value, { language })
}));

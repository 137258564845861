import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import orderBy from 'lodash/orderBy';

// A course section is considered "past"
// if more than 24 hours passed since its "Last Add Date".
// A `shift` is what gets added to `now`
// for the "Show Past Sections" widget.
// I.e. "Hide Past Sections" hides all sections
// with "Last Add Date" < now + shift.
import NEXT_DAY_TIME_OFFSET from 'common-lib/constants/nextDayTimeOffset.js';
import UTC_DATE_TIME_OFFSET from 'common-lib/constants/utcDateTimeOffset.js';

import { AcceptIcon, ConsortialTagIcon, LockIcon, OnDemandIcon, QualityMattersDesignationIcon } from '@acadeum/icons';

import {
  sortInstitutionFundingSearchFilterOptions,
  sortInstitutionLevelSearchFilterOptions
} from '../../helpers/sortInstitutionLevelAndFundingSearchFilterOptions.js';

import InstantSearchDate from '../InstantSearch/InstantSearchDate';
import InstantSearchPriceRange from '../InstantSearch/InstantSearchPriceRange';
import DatePickerComponent from '../InstantSearch/InstantSearchDatePicker';
import SwitchComponent from '../InstantSearch/InstantSearchSwitch';
import SearchFilterItem from '../SearchFilterItem';
import SearchFilterListItem from '../SearchFilterListItem';

import { getRefinementForToggleStatus } from '../InstantSearch/InstantSearchExcludePast';
import { decodeSearchState, encodeSearchState, getAttributesHavingNonDefaultFilterValues } from './searchStateUtils';
import { START_DATE_AND_END_DATE_FILTER_ATTRIBUTE_PLACEHOLDER } from './constants';
import { DivisionFilter } from './DivisionFilter';

export default function useCourseSearchFilters(props, {
  getAttributeName,
  FILTER_NAMES,
  isSmallScreen,
  searchState,
  isHS4CC
}) {
  const {
    view,
    userInstitutionId,
    userInstitutionIsTeaching,
    institutionName,
    preApproved,
    consortial,
    consortiumName,
    courseSharingGroupIds,
    viewerCourseSharingGroupIds,
    viewerCourseSharingGroupNames,
    showPrices,
    scope,
    newCourseSearchDesign
  } = props;

  function getSearchStateForGetShownFiltersList({ searchState, view, encoded }) {
    if (encoded) {
      searchState = decodeSearchState(searchState, view);
    } else {
      // eslint-disable-next-line no-unused-vars
      const { page, ...restSearchState } = searchState;
      if (view === 'sections') {
        searchState = restSearchState;
      } else {
        searchState = decodeSearchState(encodeSearchState(restSearchState, view), view);
      }
    }
    return searchState;
  }

  function getShownFiltersForSearchState({ searchState, view, encoded }) {
    searchState = getSearchStateForGetShownFiltersList({ searchState, view, encoded });
    return getAttributesHavingNonDefaultFilterValues(searchState, view);
  }

  const canDebugCourseSharingGroups = useMemo(() => {
    if (props.canDebugCourseSharingGroups) {
      // Currently, one the Admin Center website, on Course Sharing Group pages,
      // there're links to Course Share Course Search to view a list of courses
      // associated to a Course Sharing Group. When a user navigates such a link,
      // the Course Search shouldn't include a "Course Sharing Group" refinement filter
      // because the Course Sharing Group has already been specified in the URL:
      // to={`${process.env.NEXT_PUBLIC_COURSE_SHARE_SITE_URL}?%22refinementList%5BcourseSharingGroups.id%5D%5B0%5D%22=%22${courseSharingGroup.id}%22&%22view%22=%22courses%22`}
      // to={`${process.env.NEXT_PUBLIC_COURSE_SHARE_SITE_URL}?%22refinementList%5Bsession.course.courseSharingGroups.id%5D%5B0%5D%22=%22${courseSharingGroup.id}%22&%22view%22=%22sections%22`}
      if (typeof window !== 'undefined') {
        if (window.location.search.indexOf('courseSharingGroups.id') < 0) {
          return true;
        }
      }
    }
  }, [props.canDebugCourseSharingGroups]);

  const institutionFilterDefaultRefinement = useMemo(() => [props.institutionName], [props.institutionName]);
  const consortiumFilterDefaultRefinement = useMemo(() => [props.consortiumName], [props.consortiumName]);

  const getPriceNoRangeMessage = useCallback(() => 'All filtered courses priced equally', []);

  const [showCourseSharingGroupsDebugTools, setShowCourseSharingGroupsDebugTools] = useState(false);

  const courseSharingGroupIdStrings = useMemo(() => {
    if (courseSharingGroupIds) {
      return courseSharingGroupIds.map(String);
    }
    if (viewerCourseSharingGroupIds && !showCourseSharingGroupsDebugTools) {
      return viewerCourseSharingGroupIds.map(String);
    }
  }, [
    courseSharingGroupIds,
    viewerCourseSharingGroupIds,
    showCourseSharingGroupsDebugTools
  ]);

  // A list of attribute names for which filter elements should be shown (not hidden).
  const [shownFilters, setShownFilters] = useState([]);

  // Show or hide filter elements:
  // * On page load.
  // * When switching between "Saved Searches" tabs.
  // * When the user switches between "Courses" and "Sections".
  // * Every time any of the filter values change
  //   because when a user sets a filter to its default value,
  //   that filter element should be hidden.
  useEffect(() => {
    const shownFilters = getShownFiltersForSearchState({ searchState, view, encoded: false });
    if (showCourseSharingGroupsDebugTools) {
      if (!shownFilters.includes('session.course.courseSharingGroups.name')) {
        shownFilters.push('session.course.courseSharingGroups.name');
      }
    }
    // Just calling `setShownFilters(shownFilters)` results in a bug when unselecting all filter values
    // of a particular filter hides that filter from the list of filters which is not an intended behavior.
    // Preserving `prevShownFilters` works around that bug by not hiding such filters.
    // setShownFilters(shownFilters);
    setShownFilters(prevShownFilters => [...new Set([...shownFilters, ...prevShownFilters])]);
  }, [
    searchState,
    view
  ]);

  // I've commented out this effect because it's not clear what it does.
  // It would trigger on every change of `searchStateForSectionsView`,
  // i.e. it would trigger on every change of `searchState`,
  // i.e. every time any filter is changed.
  // useEffect(() => {
  //   if (!searchStateForSectionsView) {
  //     return;
  //   }
  //   setShownFilters(getAttributesHavingNonDefaultFilterValues(searchStateForSectionsView));
  // }, [searchStateForSectionsView]);

  const isFilterShown = (filterAttribute) => {
    return newCourseSearchDesign ? (shownFilters.includes(filterAttribute) ? true : undefined) : undefined;
  };

  // When "Course Sharing Groups" filter toggle is toggled "on" in "More filters" dropdown,
  // it should show "Course Sharing Groups" filter element in Course Search filters list.
  // And vice versa.
  const isCourseSharingGroupsFilterShown = isFilterShown('session.course.courseSharingGroups.name');
  const prevIsCourseSharingGroupsFilterShown = useRef(isCourseSharingGroupsFilterShown);
  useEffect(() => {
    if (isCourseSharingGroupsFilterShown !== prevIsCourseSharingGroupsFilterShown.current) {
      setShowCourseSharingGroupsDebugTools(isCourseSharingGroupsFilterShown);
    }
    prevIsCourseSharingGroupsFilterShown.current = isCourseSharingGroupsFilterShown;
  }, [
    isCourseSharingGroupsFilterShown
  ]);

  const startEndDateFilterElement = (
    <>
      <InstantSearchDate
        attribute={getAttributeName('session.startDate')}
        label="Start"
        gaLabel="Start Date"
        DatePickerComponent={DatePickerComponent}
      />
      <InstantSearchDate
        attribute={getAttributeName('session.earliestEndDate')}
        till
        label="End"
        gaLabel="End Date"
        DatePickerComponent={DatePickerComponent}
      />
    </>
  );

  const multiSelectListFilterType = newCourseSearchDesign ? 'multiSelectListDropdown' : 'multiSelectList';

  const isSearchFilterListItemHidden = (attributeName) => {
    return newCourseSearchDesign && !isSmallScreen && !shownFilters.includes(attributeName);
  };

  const multiSelectListFilters = (
    <>
      {/* When course sharing groups are fixed. */}
      {courseSharingGroupIds &&
        <SearchFilterListItem hidden>
          <SearchFilterItem
            type={multiSelectListFilterType}
            title={FILTER_NAMES['session.course.courseSharingGroups.id']}
            attribute={getAttributeName('session.course.courseSharingGroups.id')}
            defaultRefinement={courseSharingGroupIdStrings}
            hiddenSelect={isSearchFilterListItemHidden('session.course.courseSharingGroups.id')}
          />
        </SearchFilterListItem>
      }

      {/* When course sharing groups aren't fixed. */}
      {/* When "Course Sharing Groups" toggle is not enabled. */}
      {/* Restricts the courses to just the ones that're shared inside
          the course sharing groups the user's institution is a member of. */}
      {!courseSharingGroupIds && !showCourseSharingGroupsDebugTools &&
        <SearchFilterListItem hidden>
          <SearchFilterItem
            type={multiSelectListFilterType}
            title={FILTER_NAMES['session.course.courseSharingGroups.id']}
            attribute={getAttributeName('session.course.courseSharingGroups.id')}
            defaultRefinement={viewerCourseSharingGroupIds}
            hiddenSelect={isSearchFilterListItemHidden('session.course.courseSharingGroups.id')}
          />
        </SearchFilterListItem>
      }

      {/* Alternatively "virtual widgets" could be used for default refinements */}
      {/* https://community.algolia.com/react-instantsearch/guide/Default_refinements.html */}
      <SearchFilterListItem hidden={!!consortiumName}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.consortiaNames']}
          attribute={getAttributeName('session.course.institution.consortiaNames')}
          defaultRefinement={consortiumName ? consortiumFilterDefaultRefinement : undefined}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.consortiaNames')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.categories']}
          attribute={getAttributeName('session.course.categories')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.term']}
          attribute={getAttributeName('session.term')}
          transformItems={transformTermOptions}
          hiddenSelect={isSearchFilterListItemHidden('session.term')}
        />
      </SearchFilterListItem>

      {/* Alternatively "virtual widgets" could be used for default refinements */}
      {/* https://community.algolia.com/react-instantsearch/guide/Default_refinements.html */}
      {scope !== 'own' && (
        <SearchFilterListItem hidden={Boolean(institutionName)}>
          <SearchFilterItem
            searchable
            type={multiSelectListFilterType}
            title={FILTER_NAMES['session.course.institution.name']}
            attribute={getAttributeName('session.course.institution.name')}
            defaultRefinement={institutionName ? institutionFilterDefaultRefinement : undefined}
            hiddenSelect={isSearchFilterListItemHidden('session.course.institution.name')}
          />
        </SearchFilterListItem>
      )}
      <SearchFilterListItem>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.level']}
          attribute={getAttributeName('session.course.level')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.level')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.institution.state')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.state']}
          attribute={getAttributeName('session.course.institution.state')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.state')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.name')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.name']}
          attribute={getAttributeName('session.name')}
          transformItems={transformSessionOptions}
          hiddenSelect={isSearchFilterListItemHidden('session.name')}
        />
      </SearchFilterListItem>

      {!isHS4CC && (
        <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.division')}>
          <DivisionFilter
            hiddenSelect={isSearchFilterListItemHidden('session.course.division')}
            newDesigner={newCourseSearchDesign}
          />
        </SearchFilterListItem>
      )}

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.hours')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.hours']}
          attribute={getAttributeName('session.course.hours')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.hours')}
        />
      </SearchFilterListItem>

      {!consortiumName && showPrices && (view === 'sections') &&
        <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.cost')}>
          {newCourseSearchDesign ? (
            <InstantSearchPriceRange
              label={FILTER_NAMES['session.cost']}
              attribute={getAttributeName('session.cost')}
            />
          ) : (
            <SearchFilterItem
              type="range"
              title={FILTER_NAMES['session.cost']}
              attribute={getAttributeName('session.cost')}
              inputs
              currency="USD"
              noRangeMessage={getPriceNoRangeMessage}
            />
          )}
        </SearchFilterListItem>
      }

      {/* When course sharing groups aren't fixed. */}
      {/* When "Course Sharing Groups" toggle is enabled. */}
      {/* Shows a list of the available course sharing groups for selection. */}
      {/* The default selection is the list of the user's institution's course sharing groups. */}
      {!courseSharingGroupIds && showCourseSharingGroupsDebugTools &&
        <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.courseSharingGroups.name')}>
          <SearchFilterItem
            searchable
            type={multiSelectListFilterType}
            title={FILTER_NAMES['session.course.courseSharingGroups.name']}
            attribute={getAttributeName('session.course.courseSharingGroups.name')}
            defaultRefinement={viewerCourseSharingGroupNames}
            hiddenSelect={isSearchFilterListItemHidden('session.course.courseSharingGroups.name')}
          />
        </SearchFilterListItem>
      }

      <SearchFilterListItem hidden={isSearchFilterListItemHidden(START_DATE_AND_END_DATE_FILTER_ATTRIBUTE_PLACEHOLDER)}>
        <SearchFilterItem
          type={newCourseSearchDesign ? 'dateRangeDropdown' : 'dateRange'}
          title="Dates"
        >
          {startEndDateFilterElement}
        </SearchFilterItem>
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.institution.level')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.level']}
          transformItems={sortInstitutionLevelSearchFilterOptions}
          attribute={getAttributeName('session.course.institution.level')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.level')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.institution.control')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.control']}
          transformItems={sortInstitutionFundingSearchFilterOptions}
          attribute={getAttributeName('session.course.institution.control')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.control')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.institution.affiliations')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.affiliations']}
          attribute={getAttributeName('session.course.institution.affiliations')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.affiliations')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.institution.accreditations')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.accreditations']}
          attribute={getAttributeName('session.course.institution.accreditations')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.accreditations')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem
        hidden={isSearchFilterListItemHidden('session.course.institution.learningManagementSystem')}>
        <SearchFilterItem
          searchable
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.learningManagementSystem']}
          attribute={getAttributeName('session.course.institution.learningManagementSystem')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.learningManagementSystem')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.institution.country')}>
        <SearchFilterItem
          type={multiSelectListFilterType}
          title={FILTER_NAMES['session.course.institution.country']}
          attribute={getAttributeName('session.course.institution.country')}
          hiddenSelect={isSearchFilterListItemHidden('session.course.institution.country')}
        />
      </SearchFilterListItem>
    </>
  );

  const toggleFilters = (
    <>
      {!consortiumName && userInstitutionId &&
        <SearchFilterListItem hidden={preApproved}>
          <SearchFilterItem
            type="toggle"
            title={FILTER_NAMES['session.course.approvedBy']}
            icon={AcceptIcon}
            attribute={getAttributeName('session.course.approvedBy')}
            attrubuteValue={userInstitutionId}
            defaultRefinement={preApproved ? true : undefined}
            className="course-search__approved-filter"
          />
        </SearchFilterListItem>
      }

      {!consortiumName && userInstitutionId && !institutionName &&
        <SearchFilterListItem>
          <SearchFilterItem
            type="toggle"
            title={FILTER_NAMES['session.course.institution.consortialEnrollingInstitutionIds']}
            icon={ConsortialTagIcon}
            attribute={getAttributeName('session.course.institution.consortialEnrollingInstitutionIds')}
            attrubuteValue={userInstitutionId}
            defaultRefinement={consortial ? true : undefined}
            className="course-search__consortial-filter"
          />
        </SearchFilterListItem>
      }

      <SearchFilterListItem>
        <SearchFilterItem
          type="toggle"
          title={FILTER_NAMES['session.course.onDemand']}
          attribute={getAttributeName('session.course.onDemand')}
          defaultRefinement={false}
          icon={OnDemandIcon}
        />
      </SearchFilterListItem>

      <SearchFilterListItem>
        <SearchFilterItem
          type="toggle"
          title={FILTER_NAMES['session.course.hasPrerequisites']}
          attribute={getAttributeName('session.course.hasPrerequisites')}
          attrubuteValue={false}
        />
      </SearchFilterListItem>

      {(newCourseSearchDesign || (!consortiumName && !institutionName && userInstitutionIsTeaching || scope === 'own')) &&
        <SearchFilterListItem hidden={scope ==='own' || newCourseSearchDesign}>
          <SearchFilterItem
            type="toggle"
            title={FILTER_NAMES['session.course.institution.id']}
            attribute={getAttributeName('session.course.institution.id')}
            attrubuteValue={userInstitutionId}
            defaultRefinement={scope === 'own'}
          />
        </SearchFilterListItem>
      }

      <SearchFilterListItem>
        <SearchFilterItem
          type="toggle"
          title={FILTER_NAMES['session.course.institution.qualityMattersDesignation']}
          attribute={getAttributeName('session.course.institution.qualityMattersDesignation')}
          defaultRefinement={false}
          icon={QualityMattersDesignationIcon}
        />
      </SearchFilterListItem>

      {/*
      // If the "Add/Drop" UTC date is no longer than
      // 24 hours behind the current UTC time
      // (because the "Add/Drop" date is actually not UTC and
      //  could be local to any time zone, therefore the safety margin)
      // then still can drop students from course enrollment.
      // Otherwise can only withdraw them.
      */}
      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.lastAddDate')}>
        <SearchFilterItem
          type="excludePast"
          step="minute"
          inverseIndication={view === 'sections'}
          label={view === 'sections' ? 'Past Sections' : 'Available Sections'}
          title={view === 'sections' ? 'Past Sections' : 'Available Sections'}
          attribute={getAttributeName('session.lastAddDate')}
          defaultRefinement={view === 'sections'}
          controlledRefinement={getRefinementForToggleStatus(isFilterShown('session.lastAddDate'), { inverseIndication: view === 'sections' })}
          timeOffset={UTC_DATE_TIME_OFFSET + NEXT_DAY_TIME_OFFSET}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.zeroTextbookCost')}>
        <SearchFilterItem
          type="toggle"
          title={FILTER_NAMES['session.course.zeroTextbookCost']}
          attribute={getAttributeName('session.course.zeroTextbookCost')}
          controlledRefinement={isFilterShown('session.course.zeroTextbookCost')}
        />
      </SearchFilterListItem>

      <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.courseSharingGroups.name')}>
        <SwitchComponent
          Icon={LockIcon}
          label="Course Sharing Groups"
          value={showCourseSharingGroupsDebugTools}
          onChange={setShowCourseSharingGroupsDebugTools}
        />
      </SearchFilterListItem>

      {!consortiumName && (
        <>
          <SearchFilterListItem
            hidden={isSearchFilterListItemHidden('session.course.institution.regionallyAccredited')}>
            <SearchFilterItem
              type="toggle"
              attribute={getAttributeName('session.course.institution.regionallyAccredited')}
              title={FILTER_NAMES['session.course.institution.regionallyAccredited']}
              controlledRefinement={isFilterShown('session.course.institution.regionallyAccredited')}
            />
          </SearchFilterListItem>
          <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.hasFacultyCredentials')}>
            <SearchFilterItem
              type="toggle"
              title={FILTER_NAMES['session.course.hasFacultyCredentials']}
              attribute={getAttributeName('session.course.hasFacultyCredentials')}
              controlledRefinement={isFilterShown('session.course.hasFacultyCredentials')}
            />
          </SearchFilterListItem>
          <SearchFilterListItem hidden={isSearchFilterListItemHidden('session.course.hasLearningAssessments')}>
            <SearchFilterItem
              type="toggle"
              title={FILTER_NAMES['session.course.hasLearningAssessments']}
              attribute={getAttributeName('session.course.hasLearningAssessments')}
              controlledRefinement={isFilterShown('session.course.hasLearningAssessments')}
            />
          </SearchFilterListItem>
        </>
      )}
    </>
  );

  const {
    availableFiltersListOptions,
    onToggleShowFilter,
    onResetShownFilters
  } = useShownFilterOptions({
    shownFilters,
    setShownFilters,
    courseSharingGroupIds,
    canDebugCourseSharingGroups,
    consortiumName,
    showPrices,
    scope,
    institutionName,
    view,
    FILTER_NAMES
  });

  return {
    availableFiltersListOptions,
    onToggleShowFilter,
    onResetShownFilters,
    toggleFilters,
    multiSelectListFilters
  };
}

const termOrder = {
  'spring': 0,
  'summer': 1,
  'fall': 2
};

const transformTermOptions = (items) => {
  items = orderBy(items, [
    item => item.label.split(' ')[1],
    item => termOrder[item.label.split(' ')[0].toLowerCase()]
  ], ['asc', 'asc']);

  // Remove term "Fall 5138" for on-demand courses.
  // https://acadeum.atlassian.net/browse/CS-289
  items = items.filter(item => item.label !== 'Fall 5138');

  // Remove term "Open Learning", which corresponds to on-demand courses,
  // because there already is a dedicated filter toggle called "On-Demand".
  // https://acadeum.atlassian.net/browse/CS-289
  items = items.filter(item => item.label !== 'Open Learning');

  return items;
};

function transformSessionOptions(items) {
  // Remove session "On Demand", which corresponds to on-demand courses,
  // because there already is a dedicated filter toggle called "On-Demand".
  // https://acadeum.atlassian.net/browse/CS-289
  items = items.filter(item => item.label !== 'On Demand');

  return items;
}

function useShownFilterOptions({
  shownFilters,
  setShownFilters,
  courseSharingGroupIds,
  canDebugCourseSharingGroups,
  consortiumName,
  showPrices,
  scope,
  institutionName,
  view,
  FILTER_NAMES
}) {
  const availableFiltersListOptions = useMemo(() => {
    // These filter options will have a "switch" toggle next to them
    // in the dropdown list of available filters.
    const attributeNamesHavingSwitchToggleInShowOrHideFilterOption = [
      'session.lastAddDate',
      'session.course.zeroTextbookCost'
    ];

    // These filter options will have a "checkbox" toggle next to them
    // in the dropdown list of available filters.
    const attributeNamesHavingCheckboxInShowOrHideFilterOption = [
      'session.course.division',
      'session.course.institution.state',
      'session.name',
      'session.course.hours',
      START_DATE_AND_END_DATE_FILTER_ATTRIBUTE_PLACEHOLDER,
      'session.course.institution.level',
      'session.course.institution.control',
      'session.course.institution.affiliations',
      'session.course.institution.accreditations',
      'session.course.institution.learningManagementSystem',
      'session.course.institution.country'
    ];

    // These filters are always shown and can't be hidden.
    // These filter options will have a greyed-out "switch" toggle next to them
    // in the dropdown list of available filters.
    const attributeNamesForWhichFiltersAreAlwaysVisible = [
      'session.course.categories',
      'session.term',
      'session.course.level'
    ];

    if (!consortiumName) {
      attributeNamesHavingSwitchToggleInShowOrHideFilterOption.push(
        'session.course.institution.regionallyAccredited',
        'session.course.hasFacultyCredentials',
        'session.course.hasLearningAssessments'
      );
      attributeNamesForWhichFiltersAreAlwaysVisible.push('session.course.institution.consortiaNames');
      if (showPrices && (view === 'sections')) {
        attributeNamesHavingCheckboxInShowOrHideFilterOption.push('session.cost');
      }
    }

    if (scope !== 'own' && !institutionName) {
      attributeNamesForWhichFiltersAreAlwaysVisible.push('session.course.institution.name');
    }

    // If the list of Course Sharing Groups is not fixed
    // and if the user is an Acadeum Administrator who can "debug" Course Sharing Groups
    // then show a "Course Sharing Groups" filter option toggle in the dropdown list
    // of available filters.
    if (!courseSharingGroupIds && canDebugCourseSharingGroups) {
      attributeNamesHavingSwitchToggleInShowOrHideFilterOption.push('session.course.courseSharingGroups.name');
    }

    const allAttributeNames = [
      ...attributeNamesHavingSwitchToggleInShowOrHideFilterOption,
      ...attributeNamesHavingCheckboxInShowOrHideFilterOption,
      ...attributeNamesForWhichFiltersAreAlwaysVisible
    ];

    return [
      ...allAttributeNames.map((attribute) => {
        const filterOption = {
          label: FILTER_NAMES[attribute],
          name: attribute,
          checked: shownFilters.includes(attribute)
        };
        if (attribute === 'session.course.courseSharingGroups.name') {
          filterOption.icon = LockIcon;
        }
        if (attribute === 'session.lastAddDate') {
          filterOption.label = view === 'sections' ? 'Past Sections' : 'Available Sections';
        } else if (attribute === START_DATE_AND_END_DATE_FILTER_ATTRIBUTE_PLACEHOLDER) {
          filterOption.label = 'Dates';
        }
        if (attributeNamesHavingSwitchToggleInShowOrHideFilterOption.includes(attribute)) {
          filterOption.type = 'switch';
        }
        if (attributeNamesForWhichFiltersAreAlwaysVisible.includes(attribute)) {
          filterOption.disabled = true;
          filterOption.checked = true;
        }
        return filterOption;
      })
    ];
  }, [
    view,
    consortiumName,
    shownFilters,
    showPrices,
    scope,
    institutionName,
    courseSharingGroupIds,
    canDebugCourseSharingGroups
  ]);

  const onResetShownFilters = () => {
    setShownFilters([]);
  };

  const onToggleShowFilter = (name) => {
    if (shownFilters.includes(name)) {
      setShownFilters(shownFilters.filter(_ => _ !== name));
    } else {
      setShownFilters([...shownFilters, name]);
    }
  };

  return {
    availableFiltersListOptions,
    onResetShownFilters,
    onToggleShowFilter
  };
}

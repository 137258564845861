import { redirect } from 'react-pages';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

import { getErrorHandlerMiddleware } from '@acadeum/helpers';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import { notify } from '../redux/notificationModal';
import { rtkApi } from '../api/rtkApi';

export const reduxMiddleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false
}).concat([
  rtkApi.middleware,
  getErrorHandlerMiddleware({
    notify,
    redirectToErrorPage: ({ dispatch, pathname, params }) => {
      dispatch(redirect(getLocationUrl({
        pathname,
        query: params
      })));
    }
  })
]);

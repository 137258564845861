import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Select } from 'react-responsive-ui';

import { COUNTRY_OPTIONS, GENDER_OPTIONS } from '@acadeum/selection-options';

import CountryRegionSelect from '../CountryRegionSelect';
import Switch from '../Switch';
import RadioButtonGroup from '../RadioButtonGroup';
import FileUpload from '../FileUpload';
import FormFieldWrapper from '../FormFieldWrapper';

import renderAutocomplete from './renderAutocomplete.js';
import renderTextInput from './renderTextInput.js';

import { getInstitutionInputParamsForReactResponsiveUIAutocomplete } from './types/institution.js';

import isEmpty from './isEmpty.js';

import './Input.sass';

const Input = React.forwardRef(({
  description,
  example,
  required,
  alwaysShowAsteriskWhenRequired = true,
  error,
  label,
  labelHidden,
  labelInline,
  tooltip,
  multiline,
  type = 'text',
  currency,
  currencyFractional,
  options,
  includeNotAvailableOption,
  country,
  superAdmin,
  component,
  wait,
  style,
  className,
  ...rest
}, ref) => {
  if (type === 'country') {
    options = COUNTRY_OPTIONS;
    // When using an `<input/>` for value input, Chrome forces
    // showing a list of previously entered options ("autofill")
    // and doesn't provide any way of not showing that list.
    // So, not using `<Autocomplete/>` component here.
  }
  else if (type === 'gender') {
    options = GENDER_OPTIONS;
  }

  rest.ref = ref;

  // The `wait` property is passed by `easy-react-form`
  // when the form is being submitted.
  rest.readOnly = rest.readOnly || wait;

  // All input components from `react-responsive-ui` library
  // support `error` property to show the error message,
  // so there's no need to duplicate that error message
  // by showing a second error element.
  // However, if some other custom `component` is used for input,
  // `componentShowsErrorMessage` flag should be set to `true`
  // in order to show the error message, because that component
  // most likely won't render the `error` property.
  const componentShowsErrorMessage = component ? false : true;

  function renderInput() {
    if (type === 'country-region') {
      return (
        <CountryRegionSelect
          error={error}
          includeNotAvailableOption={includeNotAvailableOption}
          country={country}
          {...rest}
        />
      );
    }
    else if (type === 'institution' || type === 'homeInstitution') {
      const props = getInstitutionInputParamsForReactResponsiveUIAutocomplete({
        type,
        superAdmin
      });
      rest = {
        ...rest,
        ...props,
        placeholder: rest.placeholder || 'Select an institution'
      };
      return renderAutocomplete({
        options,
        error
      }, rest);
    }
    else if (type === 'autocomplete') {
      return renderAutocomplete({
        options,
        error
      }, rest);
    } else if (options && type !== 'radio') {
      return (
        <Select
          className="form-select-component"
          options={options}
          error={error}
          {...rest}
        />
      );
    }
    else if (type === 'switch') {
      return (
        <Switch
          error={error}
          label={rest.value ? 'On' : 'Off'}
          className="Input-switch"
          {...rest}
        />
      );
    }
    else if (type === 'checkbox') {
      return (
        <Checkbox
          error={error}
          className="Input-checkbox"
          {...rest}>
          {label}
        </Checkbox>
      );
    }
    else if (type === 'radio') {
      return (
        <RadioButtonGroup
          options={options}
          error={error}
          {...rest}
        />
      );
    }
    else if (type === 'file') {
      return (
        <FileUpload
          error={error}
          className="Input-file"
          {...rest}
        />
      );
    }
    // If input field is `<input/>`
    else {
      return renderTextInput({
        type,
        label,
        error,
        required,
        component,
        multiline,
        country,
        currency,
        currencyFractional,
        componentShowsErrorMessage
      }, rest);
    }
  }

  if (type === 'checkbox' || type === 'radio') {
    return (
      <FormFieldWrapper
        error={componentShowsErrorMessage ? undefined : error}
        errorBadge={error ? true : false}
        style={style}
        className={className}>
        {renderInput()}
      </FormFieldWrapper>
    );
  }

  return (
    <FormFieldWrapper
      required={required && (alwaysShowAsteriskWhenRequired || isEmpty(rest.value))}
      error={componentShowsErrorMessage ? undefined : error}
      errorBadge={error ? true : false}
      label={label}
      labelHidden={labelHidden}
      labelInline={labelInline}
      forInputId={rest.id}
      description={description}
      example={example}
      tooltip={tooltip}
      style={style}
      className={className}>
      {renderInput()}
    </FormFieldWrapper>
  );
});

Input.propTypes = {
  required: PropTypes.bool,
  alwaysShowAsteriskWhenRequired: PropTypes.bool,
  type: PropTypes.oneOfType([
    PropTypes.oneOf([
      'date',
      'country',
      'state',
      'gender',
      'number',
      'integer',
      'institution',
      'homeInstitution',
      'phone',
      'textarea',
      'switch',
      'checkbox',
      'radio',
      'file',
      'json',
      'currency'
    ]),
    PropTypes.string
  ]),
  currency: PropTypes.oneOf(['USD']),
  currencyFractional: PropTypes.bool,
  component: PropTypes.func,
  multiline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  labelInline: PropTypes.bool,
  description: PropTypes.node,
  example: PropTypes.node,
  tooltip: PropTypes.string,
  error: PropTypes.string,
  binary: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  superAdmin: PropTypes.bool,
  includeNotAvailableOption: PropTypes.bool,
  country: PropTypes.string,
  readOnly: PropTypes.bool,
  wait: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string
};

export default Input;

import formatGender from 'common-lib/lib/formatGender.js';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const GENDER_OPTIONS = [true, false, null].map((value) => ({
  value,
  label: formatGender(value, { language })
}));

import { useMemo } from 'react';

export function useUniqErrorsIds(cache) {
  const uniqErrorsIds: number[] = useMemo(() => {
    return cache?.parsedData.errors.reduce((uniqueIds: number[], element) => {
      if (!uniqueIds.includes(element['row'])) {
        uniqueIds.push(element['row']);
      }
      return uniqueIds;
    }, []);
  }, [cache?.parsedData.errors]);

  return uniqErrorsIds;
}

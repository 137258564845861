import React from 'react';

import InstitutionForm from '../../components/InstitutionForm';
import Section from '../../components/Section';

import actions from '../../actions';

import { useCreateInstitutionMutation } from '../../api/institutions';

const { goto } = actions;

export default function CreateInstitution() {
  const [createInstitutionMutation] = useCreateInstitutionMutation();

  async function onSubmit(values) {
    // If no `state` was selected then set the value to "N/A".
    // That's a legacy value that has been historically used for "State is unknown".
    if (!values.state) {
      values.state = 'N/A';
    }
    // `minorityServingInstitutionTypes` field is not editable in `ASP-site`.
    values.minorityServingInstitutionTypes = undefined;
    const institution = await createInstitutionMutation(values).unwrap();
    goto(`/institutions/${institution.id}`);
  }

  return (
    <Section title="Add Institution">
      <InstitutionForm
        onSubmit={onSubmit}
        submitTitle="Create"
      />
    </Section>
  );
}

CreateInstitution.meta = () => ({
  title: 'Create an Institution'
});

CreateInstitution.breadcrumbs = () => [
  ['Institutions', '/institutions'],
  'Add'
];

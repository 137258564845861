import type { FC } from 'react';
import React, { useMemo, useCallback } from 'react';

import { formatDate } from '../../../utils/format';

import type { DateRangePickerProps } from '../../DateRangePicker';
import { DateRangePicker } from '../../DateRangePicker';

import type { BaseFilterProps, FilterDefinition, UseRegisterFilterOptions } from '../context';
import { useRegisterFilter } from '../context';

interface Value { from: Date | null, to: Date | null }

type DateRangeFilterDefinition = FilterDefinition<Value>

export interface DateRangeFilterProps extends Omit<BaseFilterProps, 'getFilterLabel'>, Omit<DateRangePickerProps, 'name' | 'label' | 'value' | 'onChange'> {
  onChange?: UseRegisterFilterOptions<Value>['propsOnChange'];
  getFilterLabel?: (value: { from: Date | null, to: Date | null }, defaultLabel: string) => string;
}

export const DateRangeFilter: FC<DateRangeFilterProps> = ({
  name,
  label,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  showMonthYearPicker,
  utc,
  ...rest
}) => {
  const getFilterLabel = useCallback( (value) => {
    const defaultLabel = getDefaultLabel(value, { showMonthYearPicker, utc });
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, defaultLabel);
    }
    return defaultLabel;
  }, [propsGetFilterLabel, showMonthYearPicker, utc]);

  const filterDefinition = useMemo<DateRangeFilterDefinition>(() => ({
    name,
    label,
    getFilterLabel
  }), [name, label, getFilterLabel]);

  const {
    value,
    onChange: onChange_
  } = useRegisterFilter<Value, DateRangeFilterDefinition>({
    filter: filterDefinition,
    propsOnChange
  });

  const onChange: DateRangePickerProps['onChange'] = (range) => {
    onChange_?.({
      from: range?.[0] ? new Date(range[0]) : null,
      to: range?.[1] ? new Date(range[1]) : null
    }, filterDefinition);
  };

  return (
    <DateRangePicker
      {...rest}
      utc={utc}
      isFilter
      label={label}
      value={value ? [value.from, value.to] : undefined}
      onChange={onChange}
      showMonthYearPicker={showMonthYearPicker}
    />
  );
};

function getDefaultLabel({ from, to }, { showMonthYearPicker, utc }) {
  if (showMonthYearPicker) {
    const monthOptions = {
      utc,
      month: 'short',
      day: false
    };

    if (from && to) {
      return `${formatDate(from, monthOptions)} - ${formatDate(to, monthOptions)}`;
    }

    if (from) {
      return `${formatDate(from, monthOptions)} - ∞`;
    }

    if (to) {
      return `∞ - ${formatDate(to, monthOptions)}`;
    }
  }

  const dayOptions = {
    utc,
    month: 'short'
  };

  if (from && to) {
    return `${formatDate(from, dayOptions)} - ${formatDate(to, dayOptions)}`;
  }

  if (from) {
    return `${formatDate(from, dayOptions)} - ∞`;
  }

  if (to) {
    return `∞ - ${formatDate(to, dayOptions)}`;
  }

  return 'Any date';
}

import type { FC} from 'react';
import { useMemo } from 'react';
import React from 'react';
import { TrashIcon, XMarkIcon } from '@acadeum/icons';

import { Chip } from '../../Chip';
import { Text } from '../../Text';
import { Icon } from '../../Icon';

import { useFiltersContext } from '../context';
import { Button } from '../../Button';

import styles from './ActiveFilters.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ActiveFiltersProps {
}

export const ActiveFilters: FC<ActiveFiltersProps> = () => {
  const { removeFilter, clearAll, visibleFilters } = useFiltersContext();

  const visibleFiltersArray = useMemo(() => Object.values(visibleFilters), [visibleFilters]);

  if (visibleFiltersArray.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      {visibleFiltersArray.map((item) => {
        if (Array.isArray(item.value)) {
          return item.value.map((value) => {
            return (
              <Chip key={value} onClick={() => removeFilter(item.filter, value)}>
                <Text as="span" truncate>
                  {item.filter.getFilterLabel(value, item.filter)}
                </Text>
                <Icon icon={XMarkIcon} ml="sm"/>
              </Chip>
            );
          });
        }
        return (
          <Chip key={item.value} onClick={() => removeFilter(item.filter, null)}>
            <Text as="span" truncate>
              {item.filter.getFilterLabel(item.value, item.filter)}
            </Text>
            <Icon icon={XMarkIcon} ml="sm"/>
          </Chip>
        );
      })}
      {visibleFiltersArray.length > 0 && (
        <Button
          variant="text"
          icon={TrashIcon}
          onClick={clearAll}
        >
          Reset Filters
        </Button>
      )}
    </div>
  );
};

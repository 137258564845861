import React, { useCallback, useMemo } from 'react';

import type { Option, Options } from '@acadeum/types';
import { isObject } from '@acadeum/helpers';

import type { SelectProps } from '../../Select';
import { Select } from '../../Select';

import type { BaseFilterProps, FilterDefinition, UseRegisterFilterOptions } from '../context';
import { useRegisterFilter } from '../context';

export interface SelectFilterDefinition<V extends string> extends FilterDefinition<V> {
  options?: Option<V | null> | Options<V | null>
}

export type SelectFilterProps<V extends string = any> =  BaseFilterProps<V, SelectFilterDefinition<V>> & Omit<SelectProps<V>, 'onChange'> & {
  onChange?: UseRegisterFilterOptions<V>['propsOnChange']
  label: string;
}

export const SelectFilter = <V extends string>({
  name,
  label,
  multiple,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  options,
  ...rest
}: SelectFilterProps<V>) => {
  const getFilterLabel = useCallback((value: V, item: SelectFilterDefinition<V>) => {
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, item);
    }

    if (Array.isArray(item.options)) {
      const selectedItem = item.options.find(_ => _.value === value);
      if (selectedItem) {
        return selectedItem.label;
      }
    } else if(isObject(item.options)) {
      return item.options.label;
    }
    return item.label;
  }, [propsGetFilterLabel]);

  const filterDefinition = useMemo<SelectFilterDefinition<V>>(() => ({
    name,
    label,
    getFilterLabel,
    options
  }), [name, label, getFilterLabel, options]);

  const { value, onChange: onChange_ } = useRegisterFilter<V, SelectFilterDefinition<V>>({
    propsOnChange,
    filter: filterDefinition
  });

  const onChange = (value, selectedOptionOrOptions) => {
    onChange_(value, {
      ...filterDefinition,
      options: selectedOptionOrOptions
    });
  };

  return (
    <Select
      {...rest}
      isFilter
      label={label}
      options={options}
      multiple={multiple}
      value={value}
      onChange={onChange}
    />
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import CreateStudent from '../../../CreateStudent';
import StudentsDataUploadPage from '../../../StudentsDataUploadPage';
import type { ReduxState } from '../../../../helpers/app.types';

type AddStudentMethod = 'manual' | 'upload';

interface CreateStudentDetailsProps {
  method: AddStudentMethod
}

const CreateStudentDetails = ({ method }: CreateStudentDetailsProps) => {
  const user = useSelector((state: ReduxState) => state.auth.user);

  if (method === 'manual') {
    return (
      <CreateStudent/>
    );
  }

  return (
    <StudentsDataUploadPage
      templateLink="https://dliov3t26vp8o.cloudfront.net/Data Upload Templates/Students.xlsx"
      user={user}
    />
  );
};

CreateStudentDetails.breadcrumbs = () => [
  ['Students', '/students'],
  'Create Students'
];

export default CreateStudentDetails;

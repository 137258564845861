import type { ErrorData } from './errors';

import { isObject } from './isObject';

type HttpStatusCode = 401 | 403 | 404 | 409 | 422 | 429 | 500 | 503 | number;

interface HttpError extends Error {
  status: HttpStatusCode;
  data: ErrorData;
}

export const isHttpError = (error: unknown): error is HttpError => {
  return isObject(error) && 'status' in error && 'data' in error;
};

import React from 'react';

import { Accordion } from '@acadeum/ui';

import WelcomeLetter from '../../../../WelcomeLetter';

import { useFetchInstitutionCourseAdditionalInfoQuery } from '../../../../../api/institutions';


export function WelcomeLetterSection({
  course
}) {
  const { data: courseAdditionalInfo, isLoading } = useFetchInstitutionCourseAdditionalInfoQuery({ id: course.institution.id, courseId: course.id });

  const welcomeLetter = courseAdditionalInfo && courseAdditionalInfo.welcomeLetter;

  const hasWelcomeLetter = !isLoading && Boolean(welcomeLetter);

  return (
    <Accordion title="Welcome Letter" defaultOpen={hasWelcomeLetter}>
      <WelcomeLetter
        welcomeLetter={welcomeLetter}
        loading={isLoading}
      />
    </Accordion>
  );
}

import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { InputMask } from '@react-input/mask';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';

import { CalendarIcon } from '@acadeum/icons';

import { normalizeInboundDate, normalizeOutboundDate } from '@acadeum/helpers';
import useScreenSize from '../../utils/useScreenSize';

import { Modal, useModalDatePickerContext } from '../Modal';

// This component depends on global styles.
// When import to another repository, also need import styles from "styles/libs/react-datepicker.scss"
import styles from './DatePicker.module.scss';

const DATE_FORMAT = 'MM/dd/yyyy';
const MASK = 'mm/dd/yyyy';
export const PLACEHOLDER = 'MM/DD/YYYY';

export interface AcadeumDatePickerProps {
  id: string;
  utc?: boolean;
  minDate?: number | Date;
  maxDate?: number | Date;
  value?: number | Date;
  error?: boolean | string;
  readOnly?: boolean;
  required?: boolean;
  label?: string;
  name?: string;
  portalId?: string;
  tooltipProps?: {
    id: string;
    content: React.ReactNode;
  },
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (value: number | Date) => void;
  onSelect?: (value: number | Date | null) => void;
}

export const DatePicker = ({
  utc = true,
  value,
  onChange,
  error,
  name,
  minDate,
  maxDate,
  id,
  readOnly,
  label,
  required,
  tooltipProps,
  portalId,
  onBlur,
  onSelect
}: AcadeumDatePickerProps) => {
  const { isSmallScreen } = useScreenSize();
  const [show, setShow] = useState(false);

  const inModal = useModalDatePickerContext();

  const commonProps = {
    name,
    required,
    readOnly,
    'aria-invalid': !!error,
    'aria-describedby': id + '-hint',
    className: classNames('form-control', styles.input, {
      [styles.isInvalid]: error
    })
  };

  const renderDatepicker = (isMobileScreen = false) => (
    <ReactDatePicker
      {...commonProps}
      open={isMobileScreen ? true : undefined}
      id={id}
      portalId={portalId}
      aria-describedby={tooltipProps ? tooltipProps.id : undefined}
      onBlur={onBlur}
      minDate={normalizeInboundDate(minDate, { utc })}
      maxDate={normalizeInboundDate(maxDate, { utc })}
      formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
      placeholderText={PLACEHOLDER}
      dateFormat={DATE_FORMAT}
      selected={normalizeInboundDate(value, { utc })}
      onChange={(value) => onChange(normalizeOutboundDate(value, { utc }))}
      customInput={<InputMask mask={MASK} replacement={{ d: /\d/, m: /\d/, y: /\d/ }}/>}
      withPortal={inModal ? !isMobileScreen : false}
      onSelect={onSelect}
    />
  );

  return (
    <div className={classNames(styles.AcadeumDatePicker, {
      [styles.AcadeumDatePickerError]: !!tooltipProps
    })}>
      {isSmallScreen ? (
        <>
          <InputMask
            {...commonProps}
            mask={MASK}
            placeholder={PLACEHOLDER}
            replacement={{ d: /\d/, m: /\d/, y: /\d/ }}
            value={value ? format(normalizeInboundDate(value, { utc }), DATE_FORMAT) : ''}
            onClick={() => setShow(true)}
          />
          <Modal
            show={show}
            onHide={() => setShow(false)}
            title={label}
            closeLabel="Close"
          >
            <Modal.Body>
              {renderDatepicker(true)}
            </Modal.Body>
          </Modal>
        </>
      ) : (
        renderDatepicker()
      )}
      {tooltipProps && (
        <span className={styles.AcadeumDatePickerErrorTooltip}>
          {tooltipProps.content}
        </span>
      )}
      <span className={styles.iconWrapper}>
        <CalendarIcon className="control-icon"/>
      </span>
    </div>
  );
};

DatePicker.propTypes = {
  utc: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  id: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  tooltipId: PropTypes.string,
  errorTooltip: PropTypes.node
} as NonNullable<unknown>;

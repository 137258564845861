/* eslint-disable */
// @ts-nocheck
import React, { useId, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { validateImageLoadability } from '@acadeum/validate';
import { COUNTRY_OPTIONS } from '@acadeum/selection-options';

import isValidPhoneNumber from 'common-lib/lib/isValidPhoneNumber';
import validatePassword from 'common-lib/lib/validatePassword';
import isValidEmail from 'common-lib/lib/isValidEmail';
import isValidName from 'common-lib/lib/isValidName';
import isValidUsername from 'common-lib/lib/isValidUsername';
import isValidUrl from 'common-lib/lib/isValidUrl';

import { parseCommaSeparatedIds, getCountryOptions, isEmptyFormValue, isValidImageUrl } from '@acadeum/helpers';

import { FormPhoneInput } from '@acadeum/core-ui';

import { getTranslationLanguage } from '../../translate';

import { FormFieldWrapper } from '../FormFieldWrapper';
import { Checkbox } from '../Checkbox';

import { FormCheckboxGroup } from './ui/FormCheckboxGroup';
import { FormChoiceList } from './ui/FormChoiceList';
import { FormCurrencyInput } from './ui/FormCurrencyInput';
import { FormDateInput } from './ui/FormDateInput';
import { FormDateRangeInput } from './ui/FormDateRangeInput';
import { FormFileUpload } from './ui/FormFileUpload';
import { FormGradingScale } from './ui/FormGradingScale';
import { FormInput } from './ui/FormInput';
import { FormJSONInput } from './ui/FormJSONInput';
import { FormMarkdownInput } from './ui/FormMarkdownInput';
import { FormPhoneExtensionInput } from './ui/FormPhoneExtensionInput';
import { FormRadioInput } from './ui/FormRadioInput';
import { FormRoleInput } from './ui/FormRoleInput';
import { FormSelect } from './ui/FormSelect';
import { FormColorField } from './ui/FormColorInput';
import { FormPictureUpload } from './ui/FormPictureUpload';
import { FormLexicalEditor } from './ui/FormLexicalEditor';
import { FormIconPicker } from './ui/FormIconPicker';
import { FormPercentInput } from './ui/FormPercentInput';
import { Input } from '../Input';
import { FormChips } from './ui/FormChips';

const VALID_ID_REQ_EXP = /^[1-9][0-9]*$/;
const VALID_IDS_REQ_EXP = /^[1-9][0-9]*(,\s*[1-9][0-9]*)*$/;

export type FormFieldProps = Record<any, any>;

export const FormField: React.FC<FormFieldProps> = (props) => {
  const id = useId();

  const { formState, register, getValues } = useFormContext();
  const { errors, isSubmitting } = formState;

  const {
    Component,
    NativeComponent,
    type = 'text',
    placeholder: defaultPlaceholder,
    label: defaultLabel,
    validateNonParsed,
    parse,
    validate: validateType
  } = useMemo(() => {
    return getFieldProps(props.type, {
      confirmPasswordErrorMessage: props.confirmPasswordErrorMessage
    });
  }, [props.type]);

  const {
    name,
    required,
    label,
    labelAction,
    type: propsType = 'text',
    validate: validate_,
    readOnly,
    noMargin,
    width,
    readOnlyWhenIsSubmitting = true,
    noValidate,
    style,
    defaultValue,
    helpText,
    showFieldWrapperError: showFieldWrapperError_,
    labelTooltip,
    hidden,
    options: options_,
    parse: propsParse,
    ...rest
  } = {
    label: defaultLabel,
    placeholder: defaultPlaceholder,
    ...props
  };

  const options = propsType === 'country' ? COUNTRY_OPTIONS : options_;

  const validate = getFieldValidation({
    getValues,
    required,
    type,
    validateType,
    validate: validate_,
    noValidate
  });

  const rules = getFieldRules({
    required,
    validate,
    validateNonParsed,
    parse,
    propsParse,
    // Why is `onChange` not removed from `rest` then?
    onChange: rest.onChange,
    isCustomComponent: Boolean(Component)
  });

  let additionalProps;
  if (Component) {
    additionalProps = { rules };
  } else {
    additionalProps = register(name, rules);
  }

  const showFieldWrapperLabel = !['role', 'checkbox', 'switch', 'picture', 'icon', 'chips'].includes(type);
  const showFieldLabel = ['select', 'date', 'checkbox', 'switch', 'color', 'picture'].includes(type);
  const hasDefaultChecked = ['checkbox', 'switch'].includes(type);

  const showFieldWrapperHelpText = !['picture'].includes(type);
  const showFieldHelpText = ['picture'].includes(type);

  if (showFieldHelpText) {
    additionalProps.helpText = helpText;
  }

  let showFieldWrapperError = !['choiceList', 'picture'].includes(type);

  if (!showFieldWrapperError_ && showFieldWrapperError_ !== undefined) {
    showFieldWrapperError = showFieldWrapperError_;
  }

  const error = getErrorMessage(name, errors);

  const FieldComponent = Component || NativeComponent;

  return (
    <FormFieldWrapper
      id={id}
      required={required}
      error={showFieldWrapperError ? error : undefined}
      label={showFieldWrapperLabel ? label : undefined}
      labelAction={labelAction}
      noMargin={noMargin}
      password={type === 'password'}
      width={width}
      style={style}
      helpText={showFieldWrapperHelpText ? helpText : undefined}
      labelTooltip={labelTooltip}
      hidden={hidden}
    >
      <FieldComponent
        /**
        * Props for individual components must be validated at the lower level
        */
        {...rest}
        options={options}
        defaultValue={hasDefaultChecked ? undefined : defaultValue}
        defaultChecked={hasDefaultChecked ? defaultValue : undefined}
        id={id}
        name={name}
        type={Component ? (type === 'switch' ? 'switch' : undefined) : type}
        required={required}
        readOnly={readOnly || (readOnlyWhenIsSubmitting ? isSubmitting : false)}
        error={error}
        /**
         * Fields in which it is needed inside
         */
        label={showFieldLabel ? label : undefined}
        {...additionalProps}
      />
    </FormFieldWrapper>
  );
};

FormField.propTypes = {
  type: PropTypes.oneOf([
    'name',
    'username',
    'password',
    'confirmPassword',
    'email',
    'phone',
    'phone-ext',
    'date',
    'date-range',
    'select',
    'choiceList',
    'radio',
    'role',
    'checkbox',
    'country',
    'multi-checkboxes',
    'switch',
    'text',
    'number',
    'file',
    'id',
    'ids',
    'json',
    'url',
    'currency',
    'grading-scale',
    'color',
    'picture',
    'lexical',
    'markdown',
    'icon',
    'percent',
    'chips',
    'month'
  ]),
  name: PropTypes.string.isRequired,
  labelAction: PropTypes.shape({
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
  validate: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  noMargin: PropTypes.bool,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  width: PropTypes.oneOf(['wide']),
  readOnlyWhenIsSubmitting: PropTypes.bool,
  showFieldWrapperError: PropTypes.bool
};

function getFieldProps(type, { confirmPasswordErrorMessage }) {
  switch (type) {
    case 'date':
      return {
        Component: FormDateInput
      };
    case 'date-range':
      return {
        Component: FormDateRangeInput
      };
    case 'country':
    case 'select':
      return {
        Component: FormSelect
      };
    case 'choiceList':
      return {
        Component: FormChoiceList
      };
    case 'switch':
    case 'checkbox':
      return {
        type,
        NativeComponent: Checkbox
      };
    case 'radio':
      return {
        Component: FormRadioInput
      };
    case 'multi-checkboxes':
      return {
        Component: FormCheckboxGroup
      };
    case 'chips':
      return {
        type,
        Component: FormChips
      };
    case 'role':
      return {
        Component: FormRoleInput
      };
    case 'file':
      return {
        Component: FormFileUpload
      };
    case 'number':
      return {
        type,
        NativeComponent: FormInput,
        validateNonParsed: value => !isNaN(Number(value)),
        parse: value => Number(value)
      };
    case 'id':
      return {
        NativeComponent: FormInput,
        type: 'text',
        placeholder: 'e.g. 123472',
        validateNonParsed: value => VALID_ID_REQ_EXP.test(value),
        parse: value => Number(value),
        validate: (value) => {
          if (!VALID_ID_REQ_EXP.test(value)) {
            return `Invalid ID: “${value}“. Correct format would be “233251”`;
          }
        }
      };
    case 'ids':
      return {
        NativeComponent: FormInput,
        type: 'text',
        placeholder: 'e.g. 123472, 567458',
        validateNonParsed: value => VALID_IDS_REQ_EXP.test(value),
        parse: value => typeof value === 'string' ? parseCommaSeparatedIds(value) : value,
        validate: (value) => {
          if (!Array.isArray(value) && !VALID_IDS_REQ_EXP.test(value)) {
            return `Invalid ID: “${value}“. Correct format would be “233251,325123”`;
          }
        }
      };
    case 'currency':
      return {
        Component: FormCurrencyInput
      };
    case 'percent':
      return {
        Component: FormPercentInput
      };
    case 'phone':
      return {
        Component: props => <FormPhoneInput {...props} inputComponent={Input}/>,
        validate: (value) => {
          if (!isValidPhoneNumber(value)) {
            return 'Invalid phone number';
          }
        }
      };
    case 'phone-ext':
      return {
        Component: FormPhoneExtensionInput
      };
    case 'grading-scale':
      return {
        Component: FormGradingScale
      };
    case 'markdown':
      return {
        Component: FormMarkdownInput
      };
    case 'lexical':
      return {
        Component: FormLexicalEditor
      };
    case 'icon':
      return {
        type,
        Component: FormIconPicker
      };
    case 'email':
      return {
        type,
        NativeComponent: FormInput,
        label: 'Email',
        placeholder: 'Example: Susan.Gonzalez@schoolname.edu',
        validate: (value) => {
          if (!isValidEmail(value)) {
            return 'Invalid email';
          }
        }
      };
    case 'username':
      return {
        NativeComponent: FormInput,
        type: 'text',
        validate: (value) => {
          if (!isValidUsername(value)) {
            return 'Must start with a lowercase letter and include only lowercase letters, numbers, and single hyphens. Cannot begin or end with a hyphen';
          }
        }
      };
    case 'name':
      return {
        NativeComponent: FormInput,
        type: 'text',
        validate: (value) => {
          if (!isValidName(value)) {
            return 'The name contains invalid characters';
          }
        }
      };
    case 'password':
      return {
        type,
        NativeComponent: FormInput,
        validate: (value) => {
          const errorText = {
            tooShort: 'The password is too short. Enter eight characters or more.',
            tooWeak: 'The password is too weak. Enter eight characters or more, using both lowercase and uppercase letters and numbers. Use at least one special character.'
          };
          const error = validatePassword(value);
          return errorText[error] || error;
        }
      };
    case 'confirmPassword':
      return {
        type: 'password',
        NativeComponent: FormInput,
        validate: (value, { password }) => {
          if (value !== password) {
            return confirmPasswordErrorMessage || 'The password confirmation does not match';
          }
        }
      };
    case 'json':
      return {
        Component: FormJSONInput,
        placeholder: '{}',
        validate: (value) => {
          try {
            JSON.parse(value);
          } catch (error) {
            return error.message;
          }
        }
      };
    case 'url':
      return {
        NativeComponent: FormInput,
        type: 'text',
        placeholder: 'https://...',
        validate: (value) => {
          if (!isValidUrl(value)) {
            return 'Invalid URL';
          }
        }
      };

    case 'color':
      return {
        type,
        Component: FormColorField
      };
    case 'picture':
      return {
        type,
        Component: FormPictureUpload,
        validate: async (value) => {
          if (value) {
            return await validateImageLoadability(value);
          }
        }
      };
      case 'month':
        return {
          NativeComponent: FormInput,
          type: 'month'
        }
    default:
      return {
        type,
        NativeComponent: FormInput
      };
  }
}

function getFieldValidation({
  type,
  validate,
  validateType,
  getValues,
  noValidate
}) {
  if (validateType) {
    const originalValidate = validate;
    validate = (value) => {
      if (!noValidate) {
        const error = validateType(value, getValues());
        if (error) {
          return error;
        }
      }
      if (originalValidate) {
        return originalValidate(value, getValues());
      }
    };
  }

  // Skip validation for empty values because there's
  // a separate `required` validation for that.
  if (validate) {
    const validate_ = validate;
    validate = (value) => {
      if (isEmptyFormValue(value)) {
        // Empty.
      } else {
        return validate_(value, getValues());
      }
    };
  }

  return validate;
}

function getErrorMessage(name, errors) {
  if (name.includes('.')) {
    const path = name.split('.');
    const errorObject = path.reduce((prev, curr) => prev && prev[curr], errors);
    return errorObject?.message;
  }

  return errors[name]?.message;
}

function getFieldRules({
  required,
  validate,
  validateNonParsed,
  parse,
  propsParse,
  onChange,
  isCustomComponent
}) {
  const rules = {};

  if (parse) {
    if (propsParse) {
      throw new Error(`<FormField name="${name}"/>. You can't provide \`parse\` prop when field have default \`parse\` property.`);
    }

    if (!validateNonParsed) {
      throw new Error('`validateNonParsed` is required for parsing a form field value');
    }
    rules.setValueAs = (value) => validateNonParsed(value) ? parse(value) : value;
  }

  if (propsParse) {
    rules.setValueAs = (value) => propsParse(value);
  }

  if (required) {
    // `react-hook-form` is weird: it doesn't allow `false` value
    // when `required` "rule" is set.
    //
    // Value being `false` could happen, for example, in case of
    // "Gender" select where "Male" is `true`, "Female" is `false`,
    // and "Unspecified or non-binary" is `undefined`/`null`.
    //
    // To work around the bug with `react-hook-form` not allowing
    // `false` value when a form field is marked as `required`,
    // set up custom "required" validation via the `validate()` function
    // instead of using the standard `react-hook-form` `required` "rule".
    //
    const getRequiredLabel = () => {
      return 'Required';
    };

    // Perform `required` validation manually on "custom" components.
    if (isCustomComponent) {
      const validate_ = validate;
      validate = (value) => {
        if (isEmptyFormValue(value)) {
          return getRequiredLabel();
        }
        if (validate_) {
          return validate_(value);
        }
      };
    } else {
      // "Native" components are validated via `rule.required` validation.
      rules.required = getRequiredLabel();
    }
  }

  if (validate) {
    rules.validate = validate;
  }

  if (onChange) {
    rules.onChange = onChange;
  }

  return rules;
}

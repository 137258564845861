import React, { useRef, useState } from 'react';
import type { MutableRefObject } from 'react';

import { Form, InstitutionAutocomplete, Title } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import type { FileUploadRef } from '@acadeum/ui';
import type { DataUploadPageCache } from '@acadeum/shared-admin-ui';
import type { Student, Id } from '@acadeum/types';
import { isAcadeumAdministrator } from '@acadeum/helpers';

import UploadStudentsData from './ui/UploadStudentsData';

import styles from './StudentsDataUploadPage.module.scss';

interface UploadStudent extends Student {
  institutionId: Id;
}

const StudentsDataUploadPage = ({ templateLink, user }) => {
  const [cache, setCache] = useState<DataUploadPageCache<UploadStudent[]>>();
  const [institutionId, setInstitutionId] = useState<number>();

  const formRef = useRef() as MutableRefObject<HTMLFormElement>;
  const fileUploadRef = useRef() as MutableRefObject<FileUploadRef>;

  const t = useTranslate('StudentsDataUploadPage');

  const resetState = (resetFile = false) => {
    setCache(undefined);
    if (resetFile) {
      fileUploadRef.current.reset();
    }
  };

  const onSubmitInstitutionForm = ({ institutionId }) => {
    setInstitutionId(institutionId);
  };

  return (
    <>
      <Title marginBottom="xl">
        {t('title')}
      </Title>

      {isAcadeumAdministrator(user) && (
        <Form
          className={styles.StudentsDataUploadPage__institutionForm}
          ref={formRef}
          onSubmit={onSubmitInstitutionForm}
        >
          <InstitutionAutocomplete
            required
            superAdmin
            type="institution"
            className={styles.InstitutionAutocomplete}
            onChange={() => formRef.current.submit()}
          />
        </Form>
      )}

      {(isAcadeumAdministrator(user) ? institutionId : true) && (
        <UploadStudentsData
          templateLink={templateLink}
          institutionId={institutionId}
          user={user}
          resetState={resetState}
          cache={cache}
          updateCache={setCache}
        />
      )}
    </>
  );
};

export default StudentsDataUploadPage;

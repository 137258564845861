import { injectInstitutionsEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  endpoints: institutionEndpoints,
  useFetchInstitutionQuery,
  useFetchInstitutionForEditingQuery,
  useFetchInstitutionCourseAdditionalInfoQuery,
  useCreateInstitutionMutation,
  useLazyFetchInstitutionQuery
} = injectInstitutionsEndpoints(rtkApi);

import formatRace, { RACES } from 'common-lib/lib/formatRace.js';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const RACE_OPTIONS = RACES.concat('-').map((race) => ({
  value: race,
  label: formatRace(race, { language, input: true, own: false })
}));

export const RACE_OWN_OPTIONS = RACES.concat('-').map((race) => ({
  value: race,
  label: formatRace(race, { language, input: true, own: true })
}));

import UTC_DATE_TIME_OFFSET from '../constants/utcDateTimeOffset.js';
import END_OF_DAY_TIME_OFFSET from '../constants/endOfDayTimeOffset.js';

export default function getLastDateToWithdrawFromCourseEnrollment({
  onDemand,
  lastWithdrawalDate
}) {
  // `lastWithdrawalDate` can be `null`.
  // It can also be `undefined` because this property was introduced recently
  // and the existing APIs don't return it.
  // It's also `undefined` in legacy Algolia objects (before a global update).
  if (lastWithdrawalDate === undefined) {
    console.warn('`lastWithdrawalDate: undefined` was passed to `getLastDateToWithdrawFromCourseEnrollment()`. That\'s understandable for legacy API that doesn\'t return `lastWithdrawalDate` property yet. In such case, it should be modified to include `lastWithdrawalDate` property in the result.')
  }

  if (onDemand) {
    // A student could withdraw from an on-demand course at any time after they can no longer drop.
    // I.e. there's no maximum "last withdrawal date" for on-demand courses.
    return null;
  }

  if (lastWithdrawalDate) {
    return new Date(lastWithdrawalDate.getTime() + END_OF_DAY_TIME_OFFSET + UTC_DATE_TIME_OFFSET)
  }
}

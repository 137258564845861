import formatStudentLevel from 'common-lib/lib/formatStudentLevel.js';
import studentLevels from 'common-lib/constants/studentLevels.json';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const STUDENT_LEVEL_OPTIONS = studentLevels.map((level) => ({
  value: level,
  label: formatStudentLevel(level, { language })
}));

import React, { useState, useEffect } from 'react';
import type { JSONEditorProps, JSONEditorRef } from './types';

let JSONEditorComponent;
if (typeof window !== 'undefined') {
  import('./JSONEditorComponent')
    .then(module => JSONEditorComponent = module.default)
    .catch(error => console.error('Error loading JSONEditorComponent:', error));
}

export const JSONEditor = React.forwardRef<JSONEditorRef, JSONEditorProps>((props, ref) => {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <>
      {domLoaded && JSONEditorComponent && (
        <JSONEditorComponent {...props} ref={ref}/>
      )}
    </>
  );
});

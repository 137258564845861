import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import defaultOnDemandCourseDropDateDaysAfterStartDate
  from 'common-lib/constants/onDemandCourseDropDateDaysAfterStartDate.json';
import defaultOnDemandCourseEndDateDaysAfterStartDate
  from 'common-lib/constants/onDemandCourseEndDateDaysAfterStartDate.json';

import INSTITUTION_LEVELS from 'common-lib/constants/institutionLevels.json';
import isValidDomainName from 'common-lib/lib/isValidDomainName';
import isValidPostalCode from 'common-lib/lib/isValidPostalCode';

import {
  getValueForJsonEditor,
  getValueFromJsonEditor,
  Form,
  FormRow,
  Col,
  Row,
  FormSubmit,
  FormField,
  FormFooter,
  AppLoading,
  Modal
} from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import {
  validateInstitutionData,
  validateInstitutionSftpConfiguration,
  validateInstitutionSystemAdministratorContact,
  validateInstitutionWebhooksConfiguration
} from '@acadeum/validate';
import { useFetch } from '@acadeum/hooks';
import { getCountryRegionOptions, YES_OR_NO_OPTIONS } from '@acadeum/selection-options';

import Subsection from '../Subsection';

import { getCountryRegionLabel } from '../../helpers/format';

import actions from '../../actions';

const { fetchInstitutions } = actions;

const INITIAL_VALUES = {
  data: getValueForJsonEditor({}),
  sftp: getValueForJsonEditor(null),
  webhooks: getValueForJsonEditor(null),
  systemAdministratorContact: getValueForJsonEditor(null),
  isActive: true,
  isTeaching: false,
  country: 'US',
  stripeAccountType: 'STANDARD',
  invoiceAutoAdvancement: true,
  featuredInStudentApp: true,
  muteStudentEmails: false,
  studentApp: false,
  studentPersonalDataProtection: false,
  useMarketplace: false,
  qualityMattersDesignation: false,
  costPerCreditHour: 0,
  studentCostPerCreditHour: 0,
  onDemandCourseDropDateDaysAfterStartDate: defaultOnDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate: defaultOnDemandCourseEndDateDaysAfterStartDate
};

export default function InstitutionForm({
  institution,
  onSubmit,
  submitTitle,
  className
}) {
  const initialValues = useMemo(() => institution ? createFormValues(institution) : INITIAL_VALUES, []);

  const [country, setCountry] = useState<string>(initialValues?.country);

  const [optionsCountryRegion, setOptionsCountryRegion] = useState(getCountryRegionOptions(country));

  const [showInstitutionJsonExample, setShowInstitutionJsonExample] = useState(false);

  const {
    loading,
    data: institutions
  } = useFetch(fetchInstitutions, {
    onError: (error) => console.log('Error while loading institutions', error)
  });

  const t = useTranslate('InstitutionForm');

  const onShowInstitutionJsonExample = useCallback((event) => {
    event.preventDefault();
    setShowInstitutionJsonExample(true);
  }, [setShowInstitutionJsonExample]);

  const hideInstitutionJsonExample = useCallback(() => {
    setShowInstitutionJsonExample(false);
  }, [setShowInstitutionJsonExample]);

  const validateDomains = useCallback((value) => {
    const domains = value.trim().split(/,/).map(_ => _.trim());
    if (domains.length === 0) {
      return t('errors.emptyDomain');
    }
    const invalidDomains = domains.filter(_ => !isValidDomainName(_));
    if (invalidDomains.length > 0) {
      return t('errors.invalidDomain', {
        domains: invalidDomains.join(', ')
      });
    }
  }, []);

  const validatePostalCodeForCountry = useCallback((value) => {
    if (!isValidPostalCode(value, country)) {
      return t('errors.invalidPostalCode');
    }
  }, [country]);

  const onSubmit_ = useCallback(async (values) => {
    // Normalize form values.
    values = getFormValues(values);

    // Validate form values.
    validateInstitutionData(values.data);
    validateInstitutionSystemAdministratorContact(values.systemAdministratorContact);
    validateInstitutionSftpConfiguration(values.sftp);
    validateInstitutionWebhooksConfiguration(values.webhooks);

    // Submit form values.
    await onSubmit(values);
  }, [onSubmit]);

  return (
    <Form
      defaultValues={initialValues}
      className={classNames('form', className)}
      onSubmit={onSubmit_}>
      {({ watch, setValue }) => {
        const learningManagementSystemOther = watch('learningManagementSystem') === 'OTHER';
        const studentInformationSystemOther = watch('studentInformationSystem') === 'OTHER';

        const name = watch('name');

        const validateFullName = (value) => {
          if (value === name) {
            return t('errors.invalidFullName');
          }
        };

        const validateShortName = (value) => {
          if (value === name) {
            return t('errors.invalidShortName');
          }
        };

        const onChangeCountry = (e) => {
          const newCountry = e.target.value;
          setCountry(newCountry);
          setOptionsCountryRegion(getCountryRegionOptions(newCountry));
          setValue('state', null);
          setValue('postalCode', '');
        };

        return (
          <>
            <FormField
              
              required
              name="name"
              label={t('form.name')}
            />
            <FormRow>
              <FormField
                name="shortName"
                label={t('form.shortName')}
                validate={validateShortName}
              />

              <FormField
                name="fullName"
                label={t('form.fullName')}
                validate={validateFullName}
              />
            </FormRow>

            <FormRow>
              <FormField
                required
                type="url"
                name="url"
                label={t('form.website')}
              />
              <FormField
                required
                name="vanityUrl"
                label={t('form.vanityUrl')}
              />
            </FormRow>

            <FormRow>
              <FormField
                required
                type="url"
                name="logoUrl"
                label={t('form.logoUrl')}
              />
              <FormField
                type="url"
                name="logoRasterUrl"
                label={t('form.rasterizedLogoUrl')}
              />
            </FormRow>

            <FormRow>
              <FormField
                required
                name="isActive"
                disabled={institution ? undefined : true}
                value={institution ? institution.isActive : true}
                type="select"
                label={t('form.isActive')}
                options={YES_OR_NO_OPTIONS}
              />

              <FormField
                required
                name="isTeaching"
                label={t('form.isTeachingMember')}
                type="select"
                options={YES_OR_NO_OPTIONS}
              />
            </FormRow>

            <Row>
              <Col col={4}>
                <FormField
                  required
                  name="public"
                  label={t('form.isPublicInstitution')}
                  type="select"
                  options={YES_OR_NO_OPTIONS}
                />
              </Col>
              <Col col={4}>
                <FormField
                  required
                  name="country"
                  type="country"
                  onChange={onChangeCountry}
                  label={t('form.country')}
                />
              </Col>
              {/* "Territory". Examples: Central, Northeast, Southeast. 
              {/* https://github.com/Acadeum/Tickets/issues/568  */}
              <Col col={4}>
                <FormField
                  name="region"
                  label={t('form.territory')}
                />
              </Col>
            </Row>
            <Row>
              <Col col={8}>
                <FormField
                  name="addressLine1"
                  label={t('form.street1')}
                />
              </Col>
              <Col col={4}>
                <FormField
                  name="addressLine2"
                  label={t('form.street2')}
                />
              </Col>
            </Row>

            <Row>
              <Col col={4}>
                <FormField
                  required
                  name="city"
                  label={t('form.cityTown')}
                />
              </Col>
              <Col col={4}>
                <FormField
                  name="state"
                  type="select"
                  label={getCountryRegionLabel(country)}
                  options={optionsCountryRegion}
                />
              </Col>
              <Col col={4}>
                <FormField
                  name="postalCode"
                  validate={validatePostalCodeForCountry}
                  label={t('form.postalCode')}
                  country={country}
                />
              </Col>
            </Row>


            <FormField
              name="description"
              label={t('form.description')}
              multiline
            />

            {/* Teaching Student Welcome Letter */}
            <FormField
              name="teachingStudentWelcomeLetter"
              label={t('form.welcomeLetter')}
              multiline
            />

            {/* Teaching Student Welcome Letter for Marketplace */}
            <FormField
              name="teachingStudentWelcomeLetterForMarketplace"
              label={t('form.welcomeLetterForMarketplace')}
              multiline
            />

            {/* Transcript Request URL */}
            <FormField
              name="transcriptRequestUrl"
              label={t('form.transcriptRequestUrl')}
            />
            <FormField
              name="ipedsId"
              label={t('form.ipedsId')}
              helpText={<SchoolCodeDescription />}
            />

            <Row>
              <Col col={4}>
                <FormField
                  name="learningManagementSystem"
                  label={t('form.learningManagementSystem')}
                  options={LEARNING_MANAGEMENT_SYSTEM_OPTIONS}
                  type="select"
                />
              </Col>
              {learningManagementSystemOther &&
                <>
                  <Col col={4}>
                    <FormField
                      required
                      name="learningManagementSystemVendor"
                      label={t('form.learningManagementSystemVendor')}

                    />
                  </Col>
                  <Col col={4}>
                    <FormField
                      required
                      name="learningManagementSystemProduct"
                      label={t('form.learningManagementSystemProduct')}
                    />
                  </Col>
                </>
              }
            </Row>

            <Row>
              <Col col={4}>
                <FormField
                  type="select"
                  name="studentInformationSystem"
                  label={t('form.studentInformationSystem')}
                  options={STUDENT_INFORMATION_SYSTEM_OPTIONS}
                />
              </Col>

              {studentInformationSystemOther &&
                <>
                  <Col col={4}>
                    <FormField
                      required
                      name="studentInformationSystemVendor"
                      label={t('form.studentInformationSystemVendor')}
                    />
                  </Col>
                  <Col col={4}>
                    <FormField
                      required
                      name="studentInformationSystemProduct"
                      label={t('form.studentInformationSystemProduct')}
                    />
                  </Col>
                </>
              }
            </Row>

            <Row>
              <Col col={4}>
                <FormField
                  required
                  type="select"
                  name="level"
                  label={t('form.level')}
                  options={LEVEL_OPTIONS}
                />
              </Col>
            </Row>

            <Row>
              <Col col={4}>
                <FormField
                  required
                  name="funding"
                  type="select"
                  options={FUNDING_OPTIONS}
                  label={t('form.funding')}
                />
              </Col>
              <Col col={4}>
                <FormField
                  name="regionallyAccredited"
                  type="select"
                  options={YES_OR_NO_OR_UNKNOWN_OPTIONS}
                  label={t('form.regionallyAccredited')}
                />
              </Col>
            </Row>

            <Row>
              <Col col={4}>
                <FormField
                  required
                  label="On-Demand Drop Date"
                  name="onDemandCourseDropDateDaysAfterStartDate"
                  type="number"
                />
              </Col>
              <Col col={4}>
                <FormField
                  required
                  label="On-Demand End Date"
                  name="onDemandCourseEndDateDaysAfterStartDate"
                  type="number"
                />
              </Col>
            </Row>
            {/* Student App enable switch */}
            <FormField
              name="studentApp"
              type="switch"
              disabled={initialValues.studentApp}
              label={t('form.studentApp')}
              description={t('form.studentAppDescription')}
            />

            {/* "Featured in Student App" enable switch */}
            <FormField
              name="featuredInStudentApp"
              type="switch"
              label={t('form.featuredInStudentApp')}
              description={t('form.featuredInStudentAppDescription')}
            />

            <FormField
              name="muteStudentEmails"
              type="switch"
              label={t('form.muteStudentEmails')}
            />

            {/* Student Personally Identifiable Information protection laws compliance. */}
            <FormField
              name="studentPersonalDataProtection"
              type="switch"
              label={t('form.studentPersonalDataProtection')}
              description={t('form.studentPersonalDataProtectionDescription')}
            />

            {/* Marketplace App enable switch */}
            <FormField
              type="switch"
              name="useMarketplace"
              disabled={initialValues?.useMarketplace}
              label={t('form.useMarketplace')}
              description={t('form.useMarketplaceDescription')}
            />

            <FormField
              type="switch"
              name="qualityMattersDesignation"
              label={t('form.qualityMattersDesignation')}
              description={t('form.qualityMattersDesignationDescription')}
            />

            <Row>
              <Col col={4}>
                <FormField
                  required
                  name="stripeCustomerId"
                  label={t('form.stripeCustomerId')}
                />
              </Col>
              <Col col={4}>
                <FormField
                  name="stripeDestinationId"
                  label={t('form.stripeDestinationId')}
                />
              </Col>
              <Col col={4}>
                <FormField
                  required
                  type="select"
                  name="stripeAccountType"
                  options={STRIPE_ACCOUNT_TYPE_OPTIONS}
                  label={t('form.stripeAccountType')}
                />
              </Col>
            </Row>

            <FormRow>
              {/* Payment method. */}
              {/* description="USA-based institutions should use ACH for payments. Canada-based institutions have to use Credit Cards." */}
              <FormField
                required
                type="select"
                name="paymentMethod"
                options={PAYMENT_METHOD_OPTIONS}
                label={t('form.paymentMethod')}
              />
              <FormField
                required
                type="select"
                name="acceptedPaymentMethod"
                options={ACCEPTED_PAYMENT_METHOD_OPTIONS}
                label={t('form.acceptedPaymentMethod')}
              />
            </FormRow>

            <FormField
              name="invoiceAutoAdvancement"
              type="switch"
              label={t('form.invoiceAutoAdvancement')}
            />

            {/* Institution email domains  */}

            <FormField
              required
              name="emailDomains"
              validate={validateDomains}
              label={t('form.emailDomains')}
              labelTooltip={t('form.emailDomainsDescription')}
              helpText="concordia.edu, ctx.edu, student.ctx.edu"
            />

            {/* Consortial Enrollment Home Institutions */}

            <AppLoading inline={loading} />
            {!loading && institutions && (
              <FormField
                multiple
                isLoading={loading}
                type="select"
                name="consortialEnrollmentHomeInstitutionIds"
                label={t('form.consortialEnrollmentHomeInstitutionIds')}
                description={t('form.consortialEnrollmentHomeInstitutionIdsDescription')}
                options={institutions.map(_ => ({
                  value: _.id,
                  label: _.name
                }))}
              />
            )}

            {/* Institution data JSON */}
            <FormField
              required
              name="data"
              type="json"
              label={t('form.data')}
              onChange={(event) => {
                console.log('event', event, typeof event);
              }}
              labelAction={{
                content: t('form.seeExample'),
                onClick: onShowInstitutionJsonExample
              }}
              height={160}
            />

            {/* System Administrator Contact */}
            <FormField
              name="systemAdministratorContact"
              type="json"
              label={t('form.systemAdministratorContact')}
              labelTooltip={t('form.systemAdministratorContactDescription')}
              height={160}
            />

            {/* SFTP config */}
            <FormField
              name="sftp"
              type="json"
              label={t('form.sftp')}
              height={160}
            />

            {/* Webhooks config  */}
            <FormField
              name="webhooks"
              type="json"
              label={t('form.webhooks')}
              height={160}
            />

            <Subsection title="Estimates">
              <FormRow>
                <FormField
                  name="costPerCreditHour"
                  type="number"
                  label={t('form.costPerCreditHour')}
                />
                <FormField
                  name="studentCostPerCreditHour"
                  type="number"
                  label={t('form.studentCostPerCreditHour')}
                />
              </FormRow>
            </Subsection>
            <Modal
              title={t('modal.title')}
              onHide={hideInstitutionJsonExample}
              show={showInstitutionJsonExample}
            >
              <Modal.Body>
                <FormField
                  readOnly
                  type="json"
                  label={t('modal.example')}
                  description={t('modal.exampleDescription')}
                  name="institution-json-example"
                  defaultValue={institutionJsonExample}
                />
              </Modal.Body>
            </Modal>

            <FormFooter>
              <FormSubmit>
                {submitTitle}
              </FormSubmit>
            </FormFooter>
          </>
        );
      }}
    </Form >
  );
}

InstitutionForm.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }),
  onSubmit: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  institution: PropTypes.object,
  className: PropTypes.string
};

const institutionJsonExample = `{
  "levels": "Associate, Baccalaureate, Masters, Doctorate, and professional degrees",
  "affiliations": [
    "Roman Catholic"
  ],
  "accreditations":
  [
    {
      "name": "Southern Association of Colleges and Schools Commission on Colleges",
      "link": "http://www.sacscoc.org/"
    }
  ],
  "gradingScale":
  [
    {
      "grade": "A",
      "description": "Excellent Scholarship",
      "numeric": "93-100",
      "points": 4
    },
    {
      "grade": "IP",
      "description": "Incomplete work",
      "numeric": "50-60",
      "points": 1
    }
  ],
  "gradingScaleNotes": "The institution only offers non-credit CE courses.",
  "gradingScaleUrl": "http://uiw.edu/registrar/GradingScale.html",
  "academicCalendarUrl": "https://my.uiw.edu/registrar/academics/index.html",
  "academicCalendar":
  {
    "terms":
    [
      {
        "name": "Fall 2024",
        "sessions":
        [
          {
            "name": "Fall I",
            "startDate": "2024-08-22",
            "endDate": "2024-10-14",
            "addDropEnds": "2024-08-26"
          },
          {
            "name": "Fall II",
            "startDate": "2024-10-17",
            "endDate": "2024-12-09",
            "addDropEnds": "2024-10-21"
          }
        ]
      },
      {
        "name": "Spring 2024",
        "sessions":
        [
          {
            "name": "Spring I",
            "startDate": "2024-01-09",
            "endDate": "2024-03-03",
            "addDropEnds": "2024-01-13"
          },
          {
            "name": "Spring II",
            "startDate": "2024-03-06",
            "endDate": "2024-04-28",
            "addDropEnds": "2024-03-10"
          }
        ]
      }
    ]
  }
}`;

// Parses `redux-form` values into an `institution` object
export function getFormValues(values) {
  let {
    ipedsId,
    emailDomains,
    data,
    sftp,
    webhooks,
    consortialEnrollmentHomeInstitutionIds,
    systemAdministratorContact,
    learningManagementSystem,
    /* eslint-disable prefer-const */
    learningManagementSystemVendor,
    learningManagementSystemProduct,
    studentInformationSystem,
    studentInformationSystemVendor,
    studentInformationSystemProduct,
    ...rest
  } = values;

  // Parse JSON fields.
  data = getValueFromJsonEditor(data);
  sftp = getValueFromJsonEditor(sftp);
  webhooks = getValueFromJsonEditor(webhooks);
  consortialEnrollmentHomeInstitutionIds = typeof consortialEnrollmentHomeInstitutionIds === 'number' ? [consortialEnrollmentHomeInstitutionIds] : !consortialEnrollmentHomeInstitutionIds ? [] : consortialEnrollmentHomeInstitutionIds;
  systemAdministratorContact = getValueFromJsonEditor(systemAdministratorContact);

  // Parse IPEDS ID.
  if (ipedsId && isFinite(ipedsId)) {
    ipedsId = parseInt(ipedsId);
  }

  // Parse email domains.
  if (emailDomains) {
    emailDomains = emailDomains.split(/,/).map(_ => _.trim());
  } else {
    emailDomains = [];
  }

  // Get `learningManagementSystem` value.
  if (learningManagementSystem === 'OTHER') {
    learningManagementSystem = getTitleFromVendorAndProduct({
      vendor: learningManagementSystemVendor,
      product: learningManagementSystemProduct
    });
  }

  // Get `studentInformationSystem` value.
  if (studentInformationSystem === 'OTHER') {
    studentInformationSystem = getTitleFromVendorAndProduct({
      vendor: studentInformationSystemVendor,
      product: studentInformationSystemProduct
    });
  }

  // When an SVG logo is supplied a rasterized copy
  // should also be provided for social sharing.
  if (/\.svg$/i.test(rest.logoUrl) && !rest.logoRasterUrl) {
    throw new Error('You supplied a "vector" (SVG) logo for the institution. Social sharing also requires a rasterized copy of the logo. Upload a rasterized copy of the SVG logo and paste its URL as a "Rasterized Logo URL" field value.');
  }

  // Result
  return {
    ...rest,
    ipedsId,
    emailDomains,
    data,
    sftp,
    webhooks,
    consortialEnrollmentHomeInstitutionIds,
    systemAdministratorContact,
    learningManagementSystem,
    studentInformationSystem
  };
}

// Parses form `values` into an `institution` object.
function createFormValues({
  ipedsId,
  emailDomains,
  data,
  sftp,
  webhooks,
  systemAdministratorContact,
  learningManagementSystem,
  learningManagementSystemVendor,
  learningManagementSystemProduct,
  studentInformationSystem,
  studentInformationSystemVendor,
  studentInformationSystemProduct,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate,
  ...rest
}): any {
  // Get `learningManagementSystem` value.
  if (learningManagementSystem) {
    if (!LEARNING_MANAGEMENT_SYSTEM_OPTIONS.find(_ => _.value === learningManagementSystem)) {
      const { vendor, product } = getVendorAndProductFromTitle(learningManagementSystem);
      learningManagementSystemVendor = vendor;
      learningManagementSystemProduct = product;
      learningManagementSystem = 'OTHER';
    }
  }

  // Get `studentInformationSystem` value.
  if (studentInformationSystem) {
    if (!STUDENT_INFORMATION_SYSTEM_OPTIONS.find(_ => _.value === studentInformationSystem)) {
      const { vendor, product } = getVendorAndProductFromTitle(studentInformationSystem);
      studentInformationSystemVendor = vendor;
      studentInformationSystemProduct = product;
      studentInformationSystem = 'OTHER';
    }
  }

  return {
    ...rest,
    onDemandCourseDropDateDaysAfterStartDate: onDemandCourseDropDateDaysAfterStartDate ?? defaultOnDemandCourseDropDateDaysAfterStartDate,
    onDemandCourseEndDateDaysAfterStartDate: onDemandCourseEndDateDaysAfterStartDate ?? defaultOnDemandCourseEndDateDaysAfterStartDate,
    ipedsId: ipedsId ? String(ipedsId) : undefined,
    emailDomains: emailDomains.length === 0 ? undefined : emailDomains.join(', '),
    data: getValueForJsonEditor(data),
    sftp: getValueForJsonEditor(sftp),
    webhooks: getValueForJsonEditor(webhooks),
    systemAdministratorContact: getValueForJsonEditor(systemAdministratorContact),
    learningManagementSystem,
    learningManagementSystemVendor,
    learningManagementSystemProduct,
    studentInformationSystem,
    studentInformationSystemVendor,
    studentInformationSystemProduct
  };
}

const SchoolCodeDescription = () => (
  <span>
    Enter the institution's
    {' '}
    "IPEDS ID"
    {' '}
    (if it's present in the IPEDS database):
    {' '}
    search for the institution on the
    {' '}
    <a
      href="https://carnegieclassifications.acenet.edu/lookup/lookup.php"
      target="_blank">
      "Carnegie Classification of Institutions of Higher Education"
    </a>
    {' '}
    website
    {' '}
    by its name, click on the search result and look at the URL bar of the web browser — it will show a URL looking like
    {' '}
    <code>
      http://carnegieclassifications.iu.edu/lookup/view_institution.php?unit_id=225627&...
    </code>
    {' '}
    Copy the six digit <code>unit_id</code> parameter value and paste it in this input field.
    <br />
    {' '}
    Only <strong>if the institution is not found</strong> in the "Carnegie Classification of Institutions of Higher Education" can one leave this field blank. Otherwise, the institution will have a duplicate record in the institution input field options list when signing up on the <a target="_blank" href="https://students.acadeum.com/">student site</a>.
  </span>
);

const LEVEL_OPTIONS = INSTITUTION_LEVELS.map((level) => ({
  value: level,
  label: level
}));

const PAYMENT_METHOD_OPTIONS = [
  {
    value: 'ACH',
    label: 'ACH'
  },
  {
    value: 'CARD',
    label: 'Credit Card'
  }
];

const ACCEPTED_PAYMENT_METHOD_OPTIONS = [
  {
    value: 'ACH',
    label: 'ACH'
  },
  {
    value: 'CARD',
    label: 'Credit Card'
  }
];

// const PAYMENT_SOURCE_PREFERRED_TYPE_OPTIONS = [
//   {
//     value: 'PRIMARY',
//     label: 'Primary (ACH)'
//   },
//   {
//     value: 'BACKUP',
//     label: 'Backup (Credit Card)'
//   }
// ];

const STRIPE_ACCOUNT_TYPE_OPTIONS = [
  {
    value: 'STANDARD',
    label: 'Standard (can make payments)'
  },
  {
    value: 'CUSTOM',
    label: 'Custom (can make and receive payments)'
  }
];

const FUNDING_OPTIONS = [
  {
    value: 'Public, Not-For-Profit',
    label: 'Public, Not-For-Profit'
  },
  {
    value: 'Private, Not-For-Profit',
    label: 'Private, Not-For-Profit'
  },
  {
    value: 'Private, For-Profit',
    label: 'Private, For-Profit'
  }
];

const YES_OR_NO_OR_UNKNOWN_OPTIONS = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  },
  {
    value: null,
    label: 'Unknown'
  }
];

const STUDENT_INFORMATION_SYSTEMS = [
  {
    'vendor': 'Anthology',
    'product': 'Blackboard'
  },
  {
    'vendor': 'Anthology',
    'product': 'Student'
  },
  {
    'vendor': 'Aptron',
    'product': 'Collegix'
  },
  {
    'vendor': 'Campus Café',
    'product': 'SIS'
  },
  {
    'vendor': 'Campus Mgt',
    'product': 'CampusVue'
  },
  {
    'vendor': 'Comspec',
    'product': 'EmpowerSIS'
  },
  {
    'vendor': 'EDC',
    'product': 'Campus Anyware'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Banner'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Colleague'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Power Campus'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Quercus'
  },
  {
    'vendor': 'Genius',
    'product': 'GeniusSIS'
  },
  {
    'vendor': 'Infinite Campus',
    'product': 'SIS'
  },
  {
    'vendor': 'Institutional',
    'product': 'Prorprietary'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'EX'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'One'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'Sonis'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'CX/CARS'
  },
  {
    'vendor': 'Kellogg',
    'product': 'KRIS'
  },
  {
    'vendor': 'Maruti',
    'product': 'Poise'
  },
  {
    'vendor': 'Oasis Tech',
    'product': 'CampusNext'
  },
  {
    'vendor': 'Oracle PeopleSoft',
    'product': 'Campus Solutions'
  },
  {
    'vendor': 'Populi',
    'product': 'College Mgt Suite'
  },
  {
    'vendor': 'Quinsoft',
    'product': 'OpenCUAS'
  },
  {
    'vendor': 'Sakai',
    'product': 'Student'
  },
  {
    'vendor': 'Unit 4',
    'product': 'CAMS Enterprise'
  },
  {
    'vendor': 'University of Cambridge',
    'product': 'CamSIS'
  },
  {
    'vendor': 'Workday',
    'product': 'Student'
  }
];

const LEARNING_MANAGEMENT_SYSTEMS = [
  {
    'vendor': 'Agilix',
    'product': 'Buzz'
  },
  {
    'vendor': 'Aperero',
    'product': 'Sakai'
  },
  {
    'vendor': 'Blackboard',
    'product': 'Learn'
  },
  {
    'vendor': 'Blackboard',
    'product': 'Ultra'
  },
  {
    'vendor': 'Brightspace',
    'product': 'D2L/Desire2Learn'
  },
  {
    'vendor': 'Coursera Inc.',
    'product': 'Coursera'
  },
  {
    'vendor': 'Dialogedu',
    'product': 'Dialogedu'
  },
  {
    'vendor': 'Google',
    'product': 'Classroom Edition'
  },
  {
    'vendor': 'Google',
    'product': 'Education Plus Edition'
  },
  {
    'vendor': 'Google',
    'product': 'Education Standard'
  },
  {
    'vendor': 'Google',
    'product': 'Teaching & Learning Edition'
  },
  {
    'vendor': 'Google',
    'product': 'Workspace for Edu Fundamentals'
  },
  {
    'vendor': 'Infinite Campus',
    'product': 'LMS'
  },
  {
    'vendor': 'Institutional',
    'product': 'Proprietary'
  },
  {
    'vendor': 'Instructure',
    'product': 'Canvas'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'eL'
  },
  {
    'vendor': 'Moodle HQ',
    'product': 'Moodle'
  },
  {
    'vendor': 'OpenLMS',
    'product': 'Edu'
  },
  {
    'vendor': 'OpenLMS',
    'product': 'Totara'
  },
  {
    'vendor': 'OpenLMS',
    'product': 'Work'
  },
  {
    'vendor': 'Populi',
    'product': 'Populi LMS'
  },
  {
    'vendor': 'Powerschool',
    'product': 'Schoology'
  },
  {
    'vendor': 'Vcamp',
    'product': '360'
  },
  {
    'vendor': 'Wiley',
    'product': 'Engage'
  }
];

const STUDENT_INFORMATION_SYSTEM_OPTIONS = STUDENT_INFORMATION_SYSTEMS.map(({ vendor, product }) => ({
  value: getTitleFromVendorAndProduct({ vendor, product }),
  label: getTitleFromVendorAndProduct({ vendor, product })
})).concat({ value: 'OTHER', label: 'Other' });

const LEARNING_MANAGEMENT_SYSTEM_OPTIONS = LEARNING_MANAGEMENT_SYSTEMS.map(({ vendor, product }) => ({
  value: getTitleFromVendorAndProduct({ vendor, product }),
  label: getTitleFromVendorAndProduct({ vendor, product })
})).concat({ value: 'OTHER', label: 'Other' });

function getTitleFromVendorAndProduct({ vendor, product }) {
  return vendor + ': ' + product;
}

function getVendorAndProductFromTitle(title) {
  const [vendor, product] = title.split(': ');
  return { vendor, product };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { AppProvider, ToastContainer } from '@acadeum/ui';
import {
  AppProvider as AdminAppProvider,
  LocationProvider,
  NavigateProvider,
  OnErrorProvider,
  QueryParametersProvider
} from '@acadeum/shared-admin-ui';

import useOnError from '../../hooks/useOnError';
import useLocation from '../../hooks/useLocation';
import useQueryParameters from '../../hooks/useQueryParameters';
import { useTranslations } from '../../hooks/useTranslations';

import { getCourseLink } from '../../helpers/getCourseLink';
import { getStudentLink } from '../../helpers/getStudentLink';
import getInstitutionLink from '../../helpers/getInstitutionLink';
import getConsortiumInputParams from '../../helpers/getConsortiumInputParams';

import { ClientSettingsContextProvider } from '../../providers/clientSettings';

import AppLink from '../AppLink';
import { getInstitutionInputParams } from '../Input/types/institution';

import actions from '../../actions';

const {
  goto,
  redirect,
  goBack
} = actions;

const navigate = {
  push: goto,
  back: goBack,
  replace: redirect
};

// A wrapper element.
// Provides `redux` context, `react-intl` context, etc.
export default function Container({ store, children }) {
  const { messages, language } = useTranslations();
  return (
    <AdminAppProvider app="courseshare">
      <OnErrorProvider useOnError={useOnError}>
        <LocationProvider useLocation={useLocation}>
          <QueryParametersProvider useQueryParameters={useQueryParameters}>
            <NavigateProvider navigate={navigate}>
              <AppProvider
                language={language}
                messages={messages}
                linkComponent={AppLink}
                getCourseLink={getCourseLink}
                getStudentLink={getStudentLink}
                getInstitutionLink={getInstitutionLink}
                getConsortiumInputParams={getConsortiumInputParams}
                getInstitutionInputParams={getInstitutionInputParams}
              >
                <Provider store={store}>
                  <ClientSettingsContextProvider>
                    {children}
                  </ClientSettingsContextProvider>
                </Provider>
                <ToastContainer/>
              </AppProvider>
            </NavigateProvider>
          </QueryParametersProvider>
        </LocationProvider>
      </OnErrorProvider>
    </AdminAppProvider>
  );
}

Container.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

import type { RtkApi } from '@acadeum/helpers';
import type { Email, Id } from '@acadeum/types';
import type { UseJob } from './jobs';

export interface ChangeStudentEmailInput {
  /**
   * Student's current email address
   * */
  email: Email;
  /**
   * Student's new email address
   * */
  newEmail: Email;
  /**
   * Home Institution ID
   * */
  institutionId: Id;
}


export const injectStudentsEndpoints = (rtkApi: RtkApi, { overrideExisting = false }  = {}) => rtkApi
  .injectEndpoints({
    overrideExisting,
    endpoints: build =>({
      changeStudentEmail: build.mutation<null, ChangeStudentEmailInput>({
        query: body => ({
          url: '/students/change-email',
          method: 'POST',
          body
        })
      })
    })
  });

interface UploadStudentRow {
  firstName: string;
  lastName: string;
  middleName?: string;
}

export interface BulkCreateOrUpdateStudentsInput {
  institutionId?: Id;
  rows: UploadStudentRow[];
  dryRun?: boolean;
}

export interface BulkCreateOrUpdateStudentsOutput {
  result: {
    status: 'CREATED' | 'UPDATED' | 'UNCHANGED';
    studentId: Id;
  }[];
  status: 'RUNNING' | 'FINISHED';
}

export const getUseBulkCreateOrUpdateStudentsJob = (useJob: UseJob) => () => {
  const job = useJob();
  return (parameters: BulkCreateOrUpdateStudentsInput) => job<BulkCreateOrUpdateStudentsOutput>('students-bulkCreateOrUpdate', parameters);
};

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { useFetchWithPagination } from '@acadeum/hooks';
import {
  Actions,
  Alert,
  Button,
  ConfirmActionModal,
  DataBlock,
  Filters,
  FormModal,
  Table,
  Email,
  Blank,
  Tag,
  toast
} from '@acadeum/ui';
import type { ActionsProps } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import { getErrorData } from '@acadeum/helpers';
import { CircleCheckIcon, NoAccessIcon } from '@acadeum/icons';
import type { Student as IStudent } from '@acadeum/types';
import { STUDENT_LEVEL_OPTIONS } from '@acadeum/selection-options';

import useLocation from '../../../../hooks/useLocation';

import type { ReduxState } from '../../../../helpers/app.types';
import { formatName } from '../../../../helpers/format';
import userHasPermission from '../../../../helpers/userHasPermission';
import isAcadeumAdministrator from '../../../../helpers/isAcadeumAdministrator';
import { convertFiltersDataToSelectOptions } from '../../../../helpers/convertFiltersDataToSelectOptions';

import { useBulkActivateOrDeactivateMutation } from '../../../../api/student';

import actions from '../../../../actions';

import styles from './HomeStudents.module.scss';

interface Student extends Pick<IStudent, 'advisorEmail' | 'advisorName' | 'email' | 'firstName' | 'lastName' | 'hiStudentId' | 'id' | 'level' | 'major'> {
  isActive: boolean;
  studentId?: number;
  studentUserId?: number;
  studentUserIsActive?: boolean;
}

interface FiltersType {
  status?: string,
  level?: string[],
  major?: string[],
  advisorName?: string[]
}

const {
  fetchAllStudents,
  fetchStudentsNoRace,
  fetchStudentsForExport,
  bulkChangeStudentStatus
} = actions;

const DEFAULT_FILTERS = {
  status: 'Active',
  level: [],
  major: [],
  advisorName: []
};

const HomeStudents = () => {
  const t = useTranslate('Students');
  const tHomeStudents = useTranslate('HomeStudents');

  const location = useLocation();

  const [showActivateStudentConfirmationModal, setShowActivateStudentConfirmationModal] = useState<boolean>(false);
  const [showDeactivateStudentConfirmationModal, setShowDeactivateStudentConfirmationModal] = useState<boolean>(false);
  const [showBulkActivationStudentConfirmationModal, setShowBulkActivationStudentConfirmationModal] = useState<boolean>();
  const [selectedStudent, setSelectedStudent] = useState<Student>();
  const [selectedStudents, setSelectedStudents] = useState<Student[]>([]);
  const [selectedStudentsIds, setSelectedStudentsIds] = useState({});
  const [filters, setFilters] = useState<FiltersType>(DEFAULT_FILTERS);

  const { user } = useSelector((state: ReduxState) => state.auth);
  const { studentFilters } = useSelector((state: ReduxState) => state.students);
  const [bulkActivateOrDeactivate] = useBulkActivateOrDeactivateMutation();

  const studentsNoRaceCount: number = useSelector((state: ReduxState) => state.students.studentsNoRaceCount);
  const noRaceMode: boolean = Boolean(location.query.raceAndEthnicityNotSpecified) && studentsNoRaceCount > 0;

  const { getTableProps, refresh, onFiltersChange } = useFetchWithPagination({
    fetch: noRaceMode ? fetchStudentsNoRace : fetchAllStudents,
    defaultFilters: {
      isActive: DEFAULT_FILTERS.status === 'Active'
    },
    onError: (error) => console.error(error)
  });

  const exportDataColumns = useExportDataColumns();

  const columns = useMemo(() => [
    {
      id: 'student',
      header: t('student'),
      enableSorting: true,
      accessorFn: (row) => formatName(row),
      cell: ({ row: { original: row } }) => {
        const to = `/student${row.studentId ? '' : '-user'}s/${row.studentId || row.studentUserId}`;
        const toNoRace = `/students/${row.id}/edit?raceAndEthnicityNotSpecified=✓`;
        return (
          <DataBlock
            type="student"
            student={row}
            url={noRaceMode ? toNoRace : to}
          />
        );
      }
    },
    {
      accessorKey: 'email',
      header: t('studentEmail'),
      cell: ({ getValue }) => <Email address={getValue()} />
    },
    {
      accessorKey: 'level',
      header: t('level'),
      enableSorting: true
    },
    {
      accessorKey: 'major',
      header: t('major'),
      enableSorting: true
    },
    {
      accessorKey: 'advisorName',
      header: t('advisorName')
    },
    {
      accessorKey: 'advisorEmail',
      header: t('advisorEmail'),
      cell: ({ row: { original: row } }) => row.advisorEmail ? <Email address={row.advisorEmail} /> : <Blank />
    },
    {
      accessorKey: 'isActive',
      header: t('status'),
      cell: ({ row: { original: row } }) => <Tag variant={row.isActive ? 'ACTIVE' : 'INACTIVE'} />
    },
    {
      id: 'actions',
      size: 60,
      cell: ({ row, downloadRow }) => {
        const actions: ActionsProps['actions'] = [
          {
            title: t('edit', { global: true }),
            disabled: !userHasPermission(user, 'homeStudent:update', { orgId: user.institution.id }),
            url: row.original.studentId
              ? `/students/${row.original.studentId || row.original.id}/edit${noRaceMode
                ? '?raceAndEthnicityNotSpecified=✓'
                : ''}`
              : `/students/add?values=${encodeURIComponent(JSON.stringify({
                firstName: row.original.firstName,
                lastName: row.original.lastName,
                email: row.original.email,
                hiStudentId: row.original.hiStudentId
              }))}`
          }
        ];
        if (!noRaceMode) {
          actions.push(
            {
              title: t('seeDetails', { global: true }),
              url: `/student${row.original.studentId
                ? ''
                : '-user'}s/${row.original.studentId || row.original.studentUserId}`
            },
            {
              title: t('download', { global: true }),
              onClick: downloadRow
            }
          );
        }
        if (isAcadeumAdministrator(user) && row.original.studentUserId) {
          actions.push(
            row.original.studentUserIsActive ? {
              title: t('deactivate'),
              onClick: () => {
                setSelectedStudent(row.original);
                setShowDeactivateStudentConfirmationModal(true);
              }
            } : {
              title: t('activate'),
              onClick: () => {
                setSelectedStudent(row.original);
                setShowActivateStudentConfirmationModal(true);
              }
            }
          );
        }

        return (
          <Actions
            variant="kebab"
            actions={actions}
          />
        );
      }
    }
  ], [noRaceMode, user]);

  const statusTitle = useMemo(() => selectedStudents[0]?.studentUserIsActive ? t('deactivate') : t('activate'), [selectedStudents]);

  const STATUS_OPTIONS = [
    { value: 'Active', label: tHomeStudents('student.status.active') },
    { value: 'Inactive', label: tHomeStudents('student.status.inactive') }
  ];

  const onFiltersChange_ = async (values) => {
    const { level, major, advisorName, status } = values;

    if (!isEmpty(values)) {
      const filters = (status !== null && status !== undefined && status.length) || (level && level.length) || (major && major.length) || (advisorName && advisorName.length) ? {
        status: status || [],
        level: level || [],
        major: major || [],
        advisorName: advisorName || []
      } : {};

      setFilters(filters);
      onFiltersChange({
        filters: {
          isActive: status !== undefined && status !== null && !Array.isArray(status) ? status === 'Active' : null,
          level: level && level.length ? level : undefined,
          major: major && major.length ? major : undefined,
          advisorName: advisorName && advisorName.length ? advisorName : undefined
        }
      });
    } else {
      setFilters(values);
      onFiltersChange(values);
    }

    setSelectedStudents([]);
    setSelectedStudentsIds({});
  };

  const onSubmitStudentChangeStatus = async (action, onCloseModal, student) => {
    try {
      if (action && student) {
        await bulkActivateOrDeactivate({ action, knownStudentIds: [student.id] }).unwrap();
      }
      await refresh();
      setSelectedStudents([]);
      setSelectedStudentsIds({});
      toast.success(tHomeStudents('successMessage', {
        action: student.studentUserIsActive ? tHomeStudents('deactivated') : tHomeStudents('activated')
      }));
    } catch (error: unknown) {
      const errorData = getErrorData(error);
      toast.warn(errorData.message);
    }
    onCloseModal && onCloseModal(false);
  };

  const onStudentBulkChangeStatus = async (status) => {
    const ids = selectedStudents?.filter(
      student => (filters.status === 'Active' && student.studentUserIsActive) || (filters.status === 'Inactive' && !student.studentUserIsActive)
    ).map(student => student.studentUserId);
    const action = status === 'Active' ? 'deactivate' : 'activate';

    try {
      await bulkChangeStudentStatus(action, ids);
      setShowBulkActivationStudentConfirmationModal(false);
      setSelectedStudents([]);
      setSelectedStudentsIds({});
      await refresh();
      toast.success(tHomeStudents('bulkSuccessMessage', {
        action: status === 'Active' ? tHomeStudents('deactivated') : tHomeStudents('activated')
      }));
    } catch (error: unknown) {
      const errorData = getErrorData(error);
      toast.warn(errorData.message);
    }
  };

  return (
    <>
      <Filters
        border
        values={filters}
        onFiltersChange={onFiltersChange_}
      >
        <Filters.Row>
          <Filters.Select
            multiple
            name="level"
            label={tHomeStudents('student.level')}
            options={STUDENT_LEVEL_OPTIONS}
          />
          <Filters.Select
            multiple
            name="major"
            label={tHomeStudents('student.major')}
            options={convertFiltersDataToSelectOptions(studentFilters?.major)}
          />
          <Filters.Select
            multiple
            name="advisorName"
            label={tHomeStudents('student.advisor')}
            options={convertFiltersDataToSelectOptions(studentFilters?.advisorName)}
          />
          <Filters.Select
            name="status"
            label="Status"
            getFilterLabel={value => tHomeStudents(`student.status.${value?.toLowerCase()}`)}
            options={STATUS_OPTIONS}
          />
        </Filters.Row>
      </Filters>

      <Table
        {...getTableProps({ includeGlobalFilter: true })}
        id="students"
        enableRowSelection
        hasColumnVisibility
        columns={columns}
        translate={{
          resultText: ({ totalCount }) => t('resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
            totalCount,
            selectedRowsCount
          })
        }}
        columnPinningRight={['actions']}
        exportOptions={noRaceMode ? undefined : {
          type: 'xlsx',
          fileName: t('fileNameStudent'),
          exportDataColumns,
          fetchDataForExport: async (ids) => await getFetchAllStudentsForExport(ids.map(Number))
        }}
        rowSelectionOptions={{
          rowSelection: selectedStudentsIds,
          onRowSelectionChange: setSelectedStudentsIds
        }}
        renderTopLeftToolbarCustomActions={({ selectedRows }) => {
          if (
            selectedRows.length === 0 ||
            // Check that all student have studentUserId and have the same `studentUserIsActive` value
            !selectedRows.every(student => student.studentUserId && student.studentUserIsActive === selectedRows[0].studentUserIsActive)
          ) {
            return null;
          }

          return (
            <Button
              variant="tertiary"
              icon={selectedRows[0]?.studentUserIsActive ? NoAccessIcon : CircleCheckIcon}
              disabled={selectedRows.length === 0}
              onClick={() => {
                setSelectedStudents(selectedRows);
                setShowBulkActivationStudentConfirmationModal(true);
              }}
            >
              {selectedRows[0]?.studentUserIsActive ? t('deactivate') : t('activate')}
            </Button>
          );
        }}
      />

      {selectedStudent && selectedStudent.studentUserId && (
        <ConfirmActionModal
          show={showActivateStudentConfirmationModal}
          title={tHomeStudents('activateStudent')}
          description={tHomeStudents('activateDescription', {
            strong: (text) => <strong>{text}</strong>,
            student: formatName(selectedStudent)
          })}
          submitText={t('activate')}
          onHide={() => setShowActivateStudentConfirmationModal(false)}
          onCancel={() => setShowActivateStudentConfirmationModal(false)}
          onSubmit={() => onSubmitStudentChangeStatus('activate', setShowActivateStudentConfirmationModal, selectedStudent)}
        />
      )}

      {selectedStudent && selectedStudent.studentUserId && (
        <ConfirmActionModal
          show={showDeactivateStudentConfirmationModal}
          title={tHomeStudents('deactivateStudent')}
          description={tHomeStudents('deactivateDescription', {
            strong: (text) => <strong>{text}</strong>,
            student: formatName(selectedStudent)
          })}
          submitText={t('deactivate')}
          onHide={() => setShowDeactivateStudentConfirmationModal(false)}
          onCancel={() => setShowDeactivateStudentConfirmationModal(false)}
          onSubmit={() => onSubmitStudentChangeStatus('deactivate', setShowDeactivateStudentConfirmationModal, selectedStudent)}
        />
      )}

      {selectedStudents && selectedStudents.length > 0 && (
        <FormModal
          show={showBulkActivationStudentConfirmationModal}
          title={selectedStudents[0]?.studentUserIsActive ? tHomeStudents('deactivateStudent') : tHomeStudents('activateStudent')}
          submitText={statusTitle}
          onHide={() => setShowBulkActivationStudentConfirmationModal(false)}
          onCancel={() => setShowBulkActivationStudentConfirmationModal(false)}
          onSubmit={() => onStudentBulkChangeStatus(filters.status)}
        >
          {selectedStudents.some(student => filters.status === 'Active' && (student.studentUserIsActive === undefined || !student.studentUserIsActive)) && (
            <Alert className={styles.alert} variant="warn">
              {tHomeStudents('bulkStatusChangeNotification', {
                strong: (text) => <strong>{text}</strong>,
                status: statusTitle.toLowerCase(),
                excludedStudents: selectedStudents.filter(
                  student => (!student.studentUserId || !student.studentUserIsActive)
                ).map(
                  student => formatName(student)
                ).join(', ')
              })}
            </Alert>
          )}
          <div>
            {tHomeStudents('bulkStatusChangeDescription', {
              strong: (text) => <strong>{text}</strong>,
              status: statusTitle.toLowerCase(),
              list: () => {
                return (
                  <ul className={styles.studentsList}>
                    {selectedStudents.map((student, index) => {
                      if (!student.studentUserId && !student.studentUserIsActive) {
                        return null;
                      } else if (filters.status === 'Active' && student.isActive && !student.studentUserIsActive) {
                        return null;
                      }
                      return (
                        <li key={index}>{formatName(student)}</li>
                      );
                    })}
                  </ul>
                );
              }
            })}
          </div>
        </FormModal>
      )}
    </>
  );
};

export default HomeStudents;

const getFetchAllStudentsForExport = (ids: number[]) => {
  const pageSize = 1000;
  let page = 1;
  let allRecords = [];

  function fetchRecords() {
    // eslint-disable-next-line
    const query: any = { page, pageSize };

    // If any rows not selected, export all student rows
    if (ids.length > 0) {
      query.ids = ids; // knownStudentIds
    }

    return fetchStudentsForExport(query).then((records) => {
      allRecords = allRecords.concat(records);
      page += 1;

      if (records.length === pageSize) {
        return fetchRecords();
      } else {
        return allRecords;
      }
    });
  }

  return fetchRecords();
};

export const useExportDataColumns = () => {
  return [
    {
      id: 'HI Student ID',
      value: row => row.hiStudentId
    }, {
      id: 'Acadeum Student ID',
      value: row => row.id
    }, {
      id: 'First Name',
      value: row => row.firstName
    }, {
      id: 'Middle Name',
      value: row => row.middleName
    }, {
      id: 'Last Name',
      value: row => row.lastName
    }, {
      id: 'Phone',
      value: row => row.phone
    }, {
      id: 'Email',
      value: row => row.email
    }, {
      id: 'Address Line 1',
      value: row => row.addressLine1
    }, {
      id: 'Address Line 2',
      value: row => row.addressLine2
    }, {
      id: 'City',
      value: row => row.city
    }, {
      id: 'State',
      value: row => row.state
    }, {
      id: 'Postal Code',
      value: row => row.postalCode
    }, {
      id: 'Major',
      value: row => row.major
    }, {
      id: 'Level',
      value: row => row.level
    }, {
      id: 'Gender',
      value: row => row.gender
    }, {
      id: 'Date of Birth',
      value: row => row.dob
    }, {
      id: 'Session Start Date',
      value: row => row.startDate
    }, {
      id: 'US Citizenship',
      value: row => row.citizenship
    }, {
      id: 'State Residency',
      value: row => row.residency
    }, {
      id: 'Ethnicity',
      value: row => row.ethnicity
    }, {
      id: 'Race',
      value: row => row.races
    }, {
      id: 'Advisor Name',
      value: row => row.advisorName
    }, {
      id: 'Advisor Email',
      value: row => row.advisorEmail
    }, {
      id: 'Notes',
      value: row => row.notes
    }
  ];
};

import type {Id} from '@acadeum/types';

interface ErrorTypeByCode {
  401: 'authentication_error';
  403: 'unauthorized';
  404: 'not_found';
  409: 'conflict';
  422: 'invalid_request';
  429: 'rate_limit';
  500: 'error'; // Default
  503: 'maintenance';
}

export interface ErrorData {
  /** The error message */
  message?: string;
  /** Error type */
  type?: ErrorTypeByCode[keyof ErrorTypeByCode];
  /** Available error `code`s: server-lib/utility/errors.js */
  code?: string;
  /** If the error is related to a specific input field */
  field?: string;
  /** If the error is related to a specific input field value */
  value?: any;
  /** entity or model */
  subject?: string;
  /** entity or model id */
  subjectId?: Id;
  /** entity or model ids */
  subjectIds?: Id[];
  /** when `errors` is provided, that's the array of errors */
  errors?: Omit<ErrorData, 'errors'>[];

  // unknown properties (Ex: 'contacts' in 'user_account_request_pending')
  [key: string]: unknown;
}

export const getIsNotFoundError = (errorData) => errorData && errorData.status === 404;

export const getIsNotAuthenticatedError = (errorData) => errorData && (
  errorData.status === 401 ||
  errorData.code === 'access_token_expired' ||
  errorData.code === 'access_token_invalid' ||
  (
    // `value` contains the inactive user ID when the action was performed
    // not by the user themselves, but rather by an Acadeum admin.
    !errorData.value &&
    (errorData.code === 'account_inactive' || errorData.code === 'institution_inactive')
  )
);

export const getIsMaintenanceError = (errorData) => errorData && errorData.status === 503;

export const getIsInvalidRequest = (errorData) => {
  return errorData &&
    errorData.status &&
    errorData.status >= 400 &&
    errorData.status < 500 &&
    errorData.status !== 401 &&
    errorData.status !== 404;
};

import { Email, Integer } from 'read-excel-file';

import isValidPhoneNumber from 'common-lib/lib/isValidPhoneNumber';

import { ETHNICITY_OPTIONS, RACE_OPTIONS } from '@acadeum/selection-options';

import { parsePhone } from '../parsePhone';

export function getStudentImportSchema() {
  const ETHNICITY_VALUES = ETHNICITY_OPTIONS.map(_ => _.value);

  function parseRace(name) {
    for (const race of RACE_OPTIONS) {
      if (race.label === name) {
        return race.value;
      }
    }
  }

  function parseEthnicity(name) {
    for (const ethnicity of ETHNICITY_OPTIONS) {
      if (ethnicity.label === name) {
        return ethnicity.value;
      }
    }
  }

  function validateModernDate(date) {
    if (date.getFullYear() < 2000) {
      throw new Error('min_date_2000');
    }
  }

  function validateBirthDate(date) {
    if (date.getFullYear() < 1940) {
      throw new Error('min_date_1940');
    }

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 10);
    if (date > minDate) {
      throw new Error(`max_date_${minDate.getFullYear()}`);
    }
  }

  return {
    'INSTITUTION ID': {
      prop: 'institutionId',
      type: Integer,
      required: true
    },
    'STUDENT ID': {
      prop: 'hiStudentId',
      type: String,
      required: true
    },
    'STUDENT FIRST NAME': {
      prop: 'firstName',
      type: String,
      required: true
    },
    'STUDENT MIDDLE NAME': {
      prop: 'middleName',
      type: String
    },
    'STUDENT LAST NAME': {
      prop: 'lastName',
      type: String,
      required: true
    },
    'STUDENT EMAIL': {
      prop: 'email',
      type: Email,
      required: true
    },
    'STUDENT MOBILE PHONE': {
      prop: 'phone',
      parse(value) {
        if (!value) {
          return null;
        }
        // Even though Excel shows "Text" as the data type for phone number cells
        // having values like "2146845526", under the hood it's still written as a number
        // in such Excel files for some weird reason.
        // So the code here manually converts any numbers to strings.
        // https://github.com/Acadeum/Tickets/issues/1364
        if (typeof value === 'number') {
          value = String(value);
        }
        return parsePhone(value, { defaultCountry: 'US' });
      },
      validate: isValidPhoneNumber
    },
    'LEVEL': {
      prop: 'level',
      type: String,
      required: true,
      oneOf: [
        'Undergraduate',
        'Graduate',
        'High School',
        'Other'
      ]
    },
    'GENDER': {
      prop: 'gender',
      oneOf: [
        true,
        false,
        null
      ],
      parse(value) {
        // `false` corresponds to "Female".
        // `true` corresponds to "Male".
        // `null` corresponds to "Unspecified".
        switch (value) {
          case 'Female':
            return false;
          case 'Male':
            return true;
          case 'Unspecified':
            return null;
        }
      }
    },
    'DATE OF BIRTH': {
      prop: 'dob',
      type: Date,
      validate: validateBirthDate,
      required: true
    },
    'US CITIZENSHIP': {
      prop: 'citizenship',
      required: true,
      parse(value) {
        if (!value) {
          return null;
        }
        return value.trim().toLowerCase() === 'y' || value.trim().toLowerCase() === 'yes';
      }
    },
    'ADVISOR NAME': {
      prop: 'advisorName',
      type: String
    },
    'ADVISOR EMAIL': {
      prop: 'advisorEmail',
      type: Email
    },
    'STUDENT MAJOR': {
      prop: 'major',
      type: String,
      required: false
    },
    'START DATE': {
      prop: 'startDate',
      type: Date,
      validate: validateModernDate,
      required: false
    },
    'ADDRESS - LINE 1': {
      prop: 'addressLine1',
      type: String,
      required: true
    },
    'ADDRESS - LINE 2': {
      prop: 'addressLine2',
      type: String
    },
    'ADDRESS - CITY': {
      prop: 'city',
      type: String,
      required: true
    },
    'ADDRESS - STATE': {
      prop: 'state',
      type: String,
      required: true
    },
    'ADDRESS - COUNTRY': {
      prop: 'country',
      type: String,
      required: true
    },
    'ADDRESS - ZIP CODE': {
      prop: 'postalCode',
      type: String,
      required: true
    },
    'RESIDENCY': {
      prop: 'residency',
      type: String,
      required: false
    },
    'NOTES': {
      prop: 'notes',
      type: String
    },
    'ETHNICITY': {
      prop: 'ethnicity',
      type: String,
      required: true,
      parse(value) {
        if (value === 'Not Reported') {
          return '-';
        }
        const name = value;
        value = parseEthnicity(name);
        if (!value) {
          throw new Error(`Unsupported ethnicity: ${name}`);
        }
        return value;
      },
      oneOf: ETHNICITY_VALUES
    },
    'RACE': {
      prop: 'races',
      type: String,
      required: true,
      parse(value) {
        const values = value.split(',').map(_ => _.trim());
        if (values.length === 1 && values[0] === 'Not Reported') {
          return '-';
        }
        const unsupportedRaces = values.filter(_ => !parseRace(_));
        if (unsupportedRaces.length > 0) {
          throw new Error(`Unsupported race${unsupportedRaces.length === 1
            ? ''
            : 's'}: ${unsupportedRaces.join(', ')}`);
        }
        return values.map(parseRace);
      }
    }
  };
}

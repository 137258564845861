import React from 'react';
import HitsPerPage from './react-instantsearch-dom/widgets/HitsPerPage.js';
import Pagination from './react-instantsearch-dom/widgets/Pagination';

import './InstantSearchPagination.sass';

const RESULTS_PER_PAGE_OPTIONS = [12, 24, 60].map(_ => ({ value: _, label: String(_) }));

export default function InstantSearchPagination() {
  return (
    <div className="InstantSearch-PaginationContainer">
      <Pagination
        padding={2}
        showLast
      />
      <HitsPerPage
        label="Results per page"
        defaultRefinement={RESULTS_PER_PAGE_OPTIONS[0].value}
        items={RESULTS_PER_PAGE_OPTIONS}
      />
    </div>
  );
}

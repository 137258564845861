import formatCountry, { COUNTRIES } from 'common-lib/lib/formatCountry';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const COUNTRY_OPTIONS = COUNTRIES.map((country) => ({
  value: country,
  label: formatCountry(country, { language })
})).sort((a, b) => compareStrings(a.label, b.label));

function compareStrings(a, b) {
  // Use `String.localeCompare` if it's available.
  // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
  // Which means everyone except IE <= 10 and Safari <= 10.
  if (typeof String.prototype.localeCompare === 'function') {
    return a.localeCompare(b);
  }
  return a < b ? -1 : (a > b ? 1 : 0);
}

import React, { useState, useEffect } from 'react';

let AceEditorComponent;
if (typeof window !== 'undefined') {
  import('./AceEditorComponent')
    .then(module => AceEditorComponent = module.default)
    .catch(error => console.error('Error loading AceEditorComponent:', error));
}

// eslint-disable-next-line
export const JSONEditorNew = React.forwardRef<any, any>((props, ref) => {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      {domLoaded && AceEditorComponent && (
        <AceEditorComponent {...props} ref={ref}/>
      )}
    </>
  );
});

import Auth from '../components/Auth/index.js';
import Public from '../components/Public/index.js';

export function decorateRouteComponent(Component, {
  public: isPublic,
  layout,
  margin,
  marginTop,
  background
} = {}) {
  if (layout === false) {
    return Component;
  }
  if (isPublic) {
    return Public(Component, {
      layout,
      margin,
      marginTop,
      background
    });
  }
  return Auth(Component, {
    marginTop
  });
}
